import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../api/ClientService';
import { TradeTicketService } from '../../../api/TradeTicketService';
import { WacService } from '../../../api/WacService';
import { getCurrentDateAsYYYYMMDD, getCurrentTimeZoneDateAsYYYYMMDD } from '../../utils/dateHelper';
import { getCurrentTradeTicketIDcount } from '../../utils/tradeTicketIDHelper';
import { Stack, Button, Select, MenuItem, FormControl, TextField, Autocomplete, Snackbar, Paper, Modal, Box, Typography, FormControlLabel, Checkbox, ListItemText } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import TradeTicketsTable from './TradeTicketsTable';
import TradePreviewDialog from './TradePreviewDialog';
import CancelTradeModal from './CancelTradeModal';
import { rates } from './rates';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import usePagination from '../../../hooks/usePagination';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import * as XLSX from 'xlsx';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/**
 * A custom input component for formatting numeric values.
 * @param {object} props - The component props.
 * @param {function} props.onChange - The callback function to handle value changes.
 * @param {React.Ref} ref - The ref object for accessing the input element.
 * @returns A React component that formats numeric values.
 */
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                    name: props.name,
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
/**
 * A custom alert component that forwards the ref to the underlying MuiAlert component.
 * @param {object} props - The props passed to the component.
 * @param {React.Ref} ref - The ref to be forwarded to the underlying MuiAlert component.
 * @returns The rendered MuiAlert component with the provided props and ref.
 */
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Trade Ticket Generator
function TradeTicketGenerator({curUser, showTable, token}) {
    const [askingTermsSelect, setAskingTermsSelect] = useState('Fiat'); // initial state for select input
    const askingTermsOptions = ['Fiat', 'Crypto']; // options in the select/pulldown
    const [client, setClient] = useState({})
    const [clientName, setClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [tradeTicketID, setTradeTicketID] = useState('');
    const [tradingPair, setTradingPair] = useState('BTC/USD');
    const [action, setAction] = useState('Buy');
    const [entity, setEntity] = useState('SDM');
    const [amount, setAmount] = useState('0');
    const [displayAmount, setDisplayAmount] = useState('0')
    const [amountToTrade, setAmountToTrade] = useState('0');
    const [spread, setSpread] = useState('0');
    const [dynamicSpread, setDynamicSpread] = useState('0');
    const [feeType, setFeeType] = useState(0);
    const [spotRate, setSpotRate] = useState('0');
    const [displaySpotRate, setDisplaySpotRate] = useState('0');
    const [clientDealtRate, setClientDealtRate] = useState('0');
    const [update, setUpdate] = useState(0);
    const [clientOptions, setClientOptions] = useState([]);
    const [clientNameError, setClientNameError] = useState(null);
    const [email, setEmail] = useState('false')
    // State for loading and error state of Spread/Fee API call
    const [spreadLoading, setSpreadLoading] = useState(false);
    const [spreadError, setSpreadError] = useState(null);
    // getting trades
    const [rawTrade, setRawTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false)
    // preview dialog
    const [showPreview, setShowPreview] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [enableFeeChange, setEnableFeeChange] = useState(false);
    const [feeChangeAlert, setFeeChangeAlert] = useState(false);
    const [decimalPlace, setDecimalPlace] = useState(6);
    const [dealtDecimals,setDealtDecimals] = useState(6);
    const [tradingPairOptions, setTradingPairOptions] = useState([]);   // options in the Trading Pairs select/pulldown
    const [assetInventory, setAssetInventory] = useState(0);
    const [assetOut, setAssetOut] = useState(0);
    const [downloading, setDownloading] = useState(false);
    // const [quoteAssetInventory, setQuoteAssetInventory] = useState(0);
    const [inventoryLowWarning, setInventoryLowWarning] = useState(false);
    const clientService = new ClientService(token);
    const tradeService = new TradeTicketService(token);
    const wacService = new WacService(token);
    // CANCEL TRADE MODAL
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [tradeID, setTradeID] = useState("");
    const [previousTradeData, setPreviousTradeData] = useState(null);
    // EXEC TICKET
    const [liquidityProvider, setLiquidityProvider] = useState([]);
    const [execPrice, setExecPrice] = useState('0');
    const [platform, setPlatform] = useState('');
    const [orderId, setOrderId] = useState('');
    // handle dealt and spot change
    const [isDealtFocused, setIsDealtFocused] = useState(false);
    const [isSpotFocused, setIsSpotFocused] = useState(false);

    const [notPushToExecChat, setNotPushToExecChat] = useState(false);
    const paginationProps = usePagination();
    const [tradeTimestamp, setTradeTimestamp] = useState(dayjs());
    const handleNotPushExecChange = (event) => {
        setNotPushToExecChat(event.target.checked);
    }
    const handleLiquidityProviderChange = (event) => {
        const {
            target: { value },
        } = event;
        setLiquidityProvider(
          // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    /**
     * Formats a given date into a string representation using the Toronto time zone.
     * @param {Date} date - The date to be formatted.
     * @returns {string} The formatted date string in the format: "MMM dd, yyyy hh:mm AM/PM".
     * If the input date is null or undefined, an empty string is returned.
     */
    const tradeDateFormatter = (date) => {
        if(!date){
            return ''
        }
        const parsedDate = new Date(date);
        const torontoTimeZone = 'America/Toronto';
        // console.log(item.date);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: torontoTimeZone,
        }).format(parsedDate);
        // console.log(formattedDate);
        return formattedDate
    }
    /**
     * Handles the download of an Excel file containing compliance trade ticket data.
     */
    const handleDownloadExcel = async() => {
        setDownloading(true)
        let rawComp = await tradeService.getAllComplianceTradeTicket()
        console.log(rawComp);
        // rawComp = rawComp.data
        // let wacInfo = ['WAC:', parseFloat(wacData.weightedAvgCost).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}), '', 'Inventory Available:', parseFloat(wacData.assetQuantity).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}), '', 'USD Amount:', parseFloat(wacData.totalUsdVal).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})]
        let header = ["ID", "Date", "Pair", 'Buy/Sell', 'Client', 'Cryptocurrency Units', 'Fiat/Secomd Pair'];
        let excelData = [header]
        rawComp.map(trade => {
            let objCon = {
                id: trade.trade_id,
                date: tradeDateFormatter(trade.date),
                pair: trade.asset_pair,
                direction: trade.type,
                client: trade.client,
                units: trade.type == 'Sell' ? 
                    trade.ccy_deposited
                    : trade.ccy_purchased,//.toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5}),
                secondPair: trade.type == 'Sell' ? 
                    trade.ccy_purchased
                    : trade.ccy_deposited
            }
            excelData.push(Object.values(objCon))
            return objCon
        })
        console.log(excelData)
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'compliance');
        
        XLSX.writeFile(wb, "compliance-sdm-"+ new Date().toISOString() + ".xlsx");
        setDownloading(false)
    }
    // State for loading and error state of Trading Pairs API call
    useEffect(() => {
        const fetchTradingPairs = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + '/tradingpairs',
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const tradingPairs = data.map(item => ({
                    value: item.trading_pair,
                    label: item.trading_pair,
                })).sort((a, b) => a.value < b.value ? -1 : a.value > b.value ? 1 : 0);
                setTradingPairOptions(tradingPairs);
            } catch (error) {
                console.error('There was an error fetching trading pairs!', error);
            }
        };
    
        fetchTradingPairs();
    }, []);
    
    // Load all Trade Tickets and store them with setRawTrades
    useEffect(() => {
        setLoading(true)

        tradeService.getAllTradeTicket(paginationProps.pageSize, paginationProps.pageIndex).then(rawTrade => {
            setRawTrades(rawTrade.data)
            paginationProps.setTotalItemCount(rawTrade.totalItems)

            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [update, paginationProps.pageSize, paginationProps.pageIndex]);

    // State for loading and error state of Client Names API call
    useEffect(() => {
        // API fetching function
        const getClientNamesFromAPI = async () => {
            // Fetch data from API and return an array of client names
            const clientDataFromAPI = await clientService.getAllClients();
            // console.log(clientDataFromAPI);
            // console.log('clientDataFromAPI', clientDataFromAPI);
            let clientDataForAutocomplete = []; // initialize empty array

            // Loop through the data and format it for our autocomplete
            for (const aClientData of clientDataFromAPI) {
                let the_name = ''; // initialize empty string

                // iterate through aClientData.adamClientnames to look for name_source: 'TradeTicketName'
                for (const aClientname of aClientData.adamClientnames) {
                    if (aClientname.name_source === 'TradeTicketName') {
                        the_name = aClientname.client_name;
                        break;
                    }
                }

                // if we didn't find an adamClientname name_source: 'TradeTicketName', use the primary/Copper name
                if (the_name === '') {
                    the_name = aClientData.clientPrimaryName;
                }

                clientDataForAutocomplete.push({
                    name: the_name,
                    id: aClientData.id,
                    copperId: aClientData.clientCopperId
                });
            }            // return ['Client 1', 'Client 2', 'Client 3']; // good for testing without API data
            return clientDataForAutocomplete;
        };
    
        getClientNamesFromAPI().then(data => {
            const options = data.sort((a,b) => a.name.localeCompare(b.name)).map(client => ({
                value: client.copperId, label: client.name, id: client.copperId, copperId: client.copperId 
            }));
            setClientOptions(options);
        });
    }, []);

    // Effect to fetch Spread/Fee when clientName changes
    useEffect(() => {
        // console.log('client', client);
        // console.log('clientName', clientName);
        if (!clientName) return; // Don't fetch if no client name selected
        setSpreadLoading(true);
        setSpreadError(null);
        clientService.getSpread(client.copperId)
            .then(response => {
                // check if theres dynamic fee for the selected tradingPair
                if(!isNaN(parseFloat(response.dynamicFee[tradingPair]))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setSpread(parseFloat(response.dynamicFee[tradingPair]));
                }
                else if(!isNaN(parseFloat(response.dynamicFee['ALL']))){
                    setSpread(parseFloat(response.dynamicFee['ALL']));
                }
                else if(!isNaN(parseFloat(response.fee))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setSpread(parseFloat(response.fee));
                }
                else {
                    console.log('not a number')
                    setSpread("0")
                }
                // console.log('/api/spread response:', parseFloat(response.fee));
                // if(!isNaN(parseFloat(response.fee))) {
                //     // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                //     setSpread(parseFloat(response.fee));
                // }
                // else {
                //     console.log('not a number')
                //     setSpread("0")
                // }
                // if(!isNaN(parseFloat(response.dynamicFee))) {
                //     // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                //     setDynamicSpread(parseFloat(response.dynamicFee));
                // }
                // else {
                //     console.log('not a number')
                //     setDynamicSpread("0")
                // }
                setSpreadLoading(false);
            })
            .catch(error => {
                setSpreadError(error.message);
                setSpreadLoading(false);
            });
    }, [clientName, tradingPair]);
    const handleDealtFocus = (e) => {
        setIsDealtFocused(true);
    };
    
    const handleDealtBlur = (e) => {
        setIsDealtFocused(false);
    };

    const handleSpotFocus = (e) => {
        setIsSpotFocused(true);
    };
    
    const handleSpotBlur = (e) => {
        setIsSpotFocused(false);
    };
    /**
     * Executes a side effect when the dependencies (spread, spotRate, action, dealtDecimals) change.
     * Calculates the new client dealt rate based on the spot rate, spread, and action.
     * Updates the clientDealtRate state variable with the new value.
     */
    useEffect(() => {
        // console.log('spread', spread);
        // console.log('spotRate', spotRate);
        // console.log('action', action);
        console.log('useEffect spotRate', spotRate);
        if (spotRate && action) {
            let newClientDealtRate = 0;
            if (action === 'Buy') {
                newClientDealtRate = Number(spotRate) * (1 + Number(spread) / 100);
            } else if (action === 'Sell') {
                newClientDealtRate = Number(spotRate) * (1 - Number(spread) / 100);
            }
            askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            if (askingTermsSelect === 'Fiat') {
                setClientDealtRate(newClientDealtRate)//.toFixed(dealtDecimals));
            }
            else {
                setClientDealtRate(newClientDealtRate)//.toFixed(decimalPlace));
            }
        }
    }, [spread, action, dealtDecimals]);
    const handleSpotRateChange = (e) => {
        console.log('handleSpotRateChange', e.target.value);
        const newSpotRate = e.target.value;
        if(isSpotFocused){
            setSpotRate(newSpotRate)
            let newClientDealtRate = 0;
            if (action === 'Buy') {
                newClientDealtRate = Number(newSpotRate) * (1 + Number(spread) / 100);
            } else if (action === 'Sell') {
                newClientDealtRate = Number(newSpotRate) * (1 - Number(spread) / 100);
            }
            askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            if (askingTermsSelect === 'Fiat') {
                setClientDealtRate(newClientDealtRate)//.toFixed(dealtDecimals));
            }
            else {
                setClientDealtRate(newClientDealtRate)//.toFixed(decimalPlace));
            }
        }
    }
    useEffect(() => {
        // console.log('spread', spread);
        console.log('useEffect clientDealtRate', clientDealtRate);
        // console.log('action', action);
        if (clientDealtRate && action) {
            let newClientSpotRate = 0;
            if (action === 'Buy') {
                newClientSpotRate = Number(clientDealtRate) * (1 - Number(spread) / 100);
            } else if (action === 'Sell') {
                newClientSpotRate = Number(clientDealtRate) * (1 + Number(spread) / 100);
            }
            askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            if (askingTermsSelect === 'Fiat') {
                setSpotRate(newClientSpotRate)//.toFixed(dealtDecimals));
            }
            else {
                setSpotRate(newClientSpotRate)//.toFixed(decimalPlace));
            }
        }
    }, [spread, action, dealtDecimals]);
    const handleDealtRateChange = (e) => {
        console.log('handleDealtRateChange', e.target.value);
        const newDealtRate = e.target.value;
        if(isDealtFocused){
            setClientDealtRate(newDealtRate)
            let newClientSpotRate = 0;
            if (action === 'Buy') {
                newClientSpotRate = Number(newDealtRate) * (1 - Number(spread) / 100);
            } else if (action === 'Sell') {
                newClientSpotRate = Number(newDealtRate) * (1 + Number(spread) / 100);
            }
            askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            if (askingTermsSelect === 'Fiat') {
                setSpotRate(newClientSpotRate)//.toFixed(dealtDecimals));
            }
            else {
                setSpotRate(newClientSpotRate)//.toFixed(decimalPlace));
            }
        }
        
    }
        
    // Event for auto-calculation of Amount to Trade
    useEffect(() => {
        if (amount && clientDealtRate && action && askingTermsSelect) {
            let newAmountToTrade = 0;
            if (askingTermsSelect === 'Fiat') {
                newAmountToTrade = Number(amount) / Number(clientDealtRate);
            } else if (askingTermsSelect === 'Crypto') {
                newAmountToTrade = Number(amount) * Number(clientDealtRate);
            }
            // console.log('newAmountToTrade', newAmountToTrade);
            setAmountToTrade(newAmountToTrade)//.toFixed(decimalPlace).toString());//.toFixed(2));
        }
    }, [amount, clientDealtRate, action, askingTermsSelect, decimalPlace]);

    // Automatically update TradeTicketID whenever Trading Pair or Action changes
    useEffect(() => {
        getCurrentTradeTicketIDcount().then((tradeTicketNumber) => {
            const [tradingPair1, tradingPair2] = tradingPair.split('/');
            let theTradeTicketID = entity;  // string to build Trade Ticket ID
            theTradeTicketID += getCurrentTimeZoneDateAsYYYYMMDD();
            theTradeTicketID += '-';
            theTradeTicketID += action.charAt(0);
            theTradeTicketID += '-';
            theTradeTicketID += tradingPair1;
            theTradeTicketID += tradingPair2;
            console.log(theTradeTicketID);
            // increment Trade Ticket number; last "current" number (tradeTicketNumber) comes as string!
            const currentTradeTicketNumber = 1 + Number(tradeTicketNumber);
            // Pad the tradeTicketNumber to 3 digits and convert back to string
            const paddedTradeTicketNumber = currentTradeTicketNumber.toString().padStart(3, '0');
            theTradeTicketID += paddedTradeTicketNumber;
            console.log('theTradeTicketID', theTradeTicketID);
            setTradeTicketID(theTradeTicketID);
        });
    }, [tradingPair, action, update, entity]);
    /**
     * Retrieves the newest trade ID before submission by generating a unique trade ticket ID if trade cant be pushed.
     */
    const getNewestTradeIdBeforeSubmission = async() => {
        let tradeTicketNumber = await getCurrentTradeTicketIDcount()
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        let theTradeTicketID = entity;  // string to build Trade Ticket ID
        theTradeTicketID += getCurrentTimeZoneDateAsYYYYMMDD();
        theTradeTicketID += '-';
        theTradeTicketID += action.charAt(0);
        theTradeTicketID += '-';
        theTradeTicketID += tradingPair1;
        theTradeTicketID += tradingPair2;
        console.log(theTradeTicketID);
        // increment Trade Ticket number; last "current" number (tradeTicketNumber) comes as string!
        const currentTradeTicketNumber = 1 + Number(tradeTicketNumber);
        // Pad the tradeTicketNumber to 3 digits and convert back to string
        const paddedTradeTicketNumber = currentTradeTicketNumber.toString().padStart(3, '0');
        theTradeTicketID += paddedTradeTicketNumber;
        console.log('theTradeTicketID', theTradeTicketID);
        return (theTradeTicketID);
    }
    /**
     * A useEffect hook that updates the decimal places for trading pairs based on the selected trading pair and asking terms.
     * @param {string} tradingPair - The selected trading pair.
     * @param {string} action - The action being performed.
     * @param {string} askingTermsSelect - The selected asking terms.
     */
    useEffect(() => {
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(tradingPair1 && tradingPair2){
            let rate = rates[tradingPair1] ? rates[tradingPair1].decimal : 8
            let dealt = rates[tradingPair1] ? rates[tradingPair1][tradingPair]? rates[tradingPair1][tradingPair] : rates[tradingPair1].decimal: 8
            if (askingTermsSelect === 'Fiat') {
                setDecimalPlace(rate)
                setDealtDecimals(dealt)
            }
            else {
                setDealtDecimals(rate)
                setDecimalPlace(dealt)
            }
        }    
    },[tradingPair, action, askingTermsSelect])

    /**
     * Executes a side effect when the trading pair or action changes.
     * If the action is 'Buy', it retrieves the available inventory for the first asset in the trading pair
     * and updates the state with the result.
     * If the action is not 'Buy' and the second asset in the trading pair is not one of ['CAD', 'USD', 'EUR', 'GBP'],
     * it retrieves the available inventory for the second asset and updates the state with the result.
     * If the second asset is one of ['CAD', 'USD', 'EUR', 'GBP'], it sets the asset inventory to 0,
     * sets the asset out to null, and sets the inventory low warning to false.
     * @param {string} tradingPair
     */
    useEffect(() => {
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(action === 'Buy') {
            getAssetAvailableInventory(tradingPair1).then(res => setAssetInventory(res))
        }
        else {
            if(!['CAD', 'USD', 'EUR', 'GBP'].includes(tradingPair2)){
                getAssetAvailableInventory(tradingPair2).then(res => setAssetInventory(res))
            }
            else {
                setAssetInventory(0)
                setAssetOut(null)
                setInventoryLowWarning(false)
            }
        }
    }, [tradingPair, action])

    /**
     * A useEffect hook that checks the asset inventory and sets the inventory low warning state
     * based on the trading action and amount.
     * @param {string} tradingPair - The trading pair in the format 'pair1/pair2'.
     * @param {number} assetInventory - The current inventory of the asset.
     * @param {string} action - The trading action ('Buy' or 'Sell').
     * @param {number} amount - The amount of the asset to trade.
     * @param {number} amountToTrade - The amount of the counter asset to trade.
     */
    useEffect(() => {
        console.log(assetInventory);
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(assetInventory){

            if(action === 'Buy') {
                // inventory out is related to Amount To Trade
                if(parseFloat(amountToTrade) > assetInventory) {
                    setInventoryLowWarning(true)
                    // alert('NOT ENOUGHT INVENTORY')
                }
                else{
                    setInventoryLowWarning(false)
                }
            }
            else {
                if(!['CAD', 'USD', 'EUR', 'GBP'].includes(tradingPair2)){
                    // we look at amount for inventory out 
                    if(parseFloat(amount) > assetInventory) {
                        setInventoryLowWarning(true)
                        // alert('NOT ENOUGHT INVENTORY')
                    }
                    else{
                        setInventoryLowWarning(false)
                    }
                }
                
            }
        }
        
    }, [amount, amountToTrade, action])

    /**
     * Retrieves the available inventory for a given asset.
     * @param {string} asset - The asset to retrieve the inventory for.
     */
    const getAssetAvailableInventory = async(asset) => {
        setAssetOut(asset)
        let result = await wacService.getWACByAsset(asset)
        if(result){
            return parseFloat(result.assetQuantity)
        }
        else {
            return 0
        }
    }
    const handleEnableFeeChangeDialog = () => {
        setFeeChangeAlert(true)
    }

    const handleConfirmEnableFeeChange = () => {
        setEnableFeeChange(true)
        setFeeChangeAlert(false)
    }

    /**
     * Submits a trade ticket and handles the response.
     */
    async function submittedTradeTicket(res) {
        let result = await tradeService.createTradeTicket(res);
        // console.log('result', result);
        if(result.trade_id){
            // trigger increment function 
            let id_result = await tradeService.updateTradeId();
            // @TODO: handle error
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Creating trade successfully!")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Creating trade failed!")
            return false
        }
    }

    async function submittedCanceldTradeTicket(res) {
        let result = await tradeService.createCancelTradeTicket(res);
        // console.log('result', result);
        if(result.trade_id){
            // trigger increment function 
            let id_result = await tradeService.updateTradeId();
            // @TODO: handle error
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Replaced or Canceled trade successfully!")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Creating trade failed!")
            return false
        }
    }

    /**
     * Handles the change of the fee type.
     * If the current fee type is 0, it sets the fee type to default client spread.
     * If the current fee type is 1, it sets the fee type to client dynamic fee based on their trading volume.
     */
    const handleChangeFeeType = () => {
        if(feeType === 0) {
            setFeeType(1)
        }
        else {
            setFeeType(0)
        }
    } 
    const handleSubmit = async() => {
        // console.log('handleSubmit running');
        setSubmitLoading(true)
        //event.preventDefault();
        let count = update + 1
        // console.log('clientName', clientName);
        // console.log('clientOptions', clientOptions);
        if (!clientName) {// && !clientOptions.map(option => option.value).includes(clientName)) {
            setClientNameError('Invalid client name. Please select from the dropdown.');
            return;
        }

        if (clientName) {
            let ccyDeposited = 
                askingTermsSelect === 'Fiat'? 
                    action === 'Buy'? 
                        tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ? 
                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal : 5}) + ' ' + tradingPair.split('/')[1]
                        :tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? 
                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
                    : action === 'Buy'? 
                        tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ?
                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 5}) + ' ' + tradingPair.split('/')[1]
                        :tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? 
                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
            let ccyPurchased =
                askingTermsSelect === 'Fiat'? 
                    action === 'Buy'? 
                        tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal: 8}) + ' ' + tradingPair.split('/')[0]
                        : tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8}) + ' ' + tradingPair.split('/')[1]
                    : action === 'Buy'? 
                        tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8}) + ' ' + tradingPair.split('/')[0]
                        : tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8}) + ' ' + tradingPair.split('/')[1]
            //let curDate = new Date((new Date()).toLocaleDateString("en-US", {timeZone: "America/New_York"})).toISOString()
            let curDate = dayjs(tradeTimestamp).toISOString();
            console.log('tradeTimestamp', tradeTimestamp);
            console.log('curDate', curDate);
            let formattedSpotRate = askingTermsSelect === 'Fiat'? 
                parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let formattedDealtRate = askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let liquidityProviders = liquidityProvider.join(', ')
            let execAmt = action === 'Buy'? ccyPurchased:ccyDeposited
            // askingTermsSelect == 'Fiat'? 
            //     parseFloat(amountToTrade).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
            //     :parseFloat(amount).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
            console.log('Running submittedTradeTicket');
            //tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
            // ADDED FINAL CHECK FOR MOST UPDATED TRADE TICKET ID
            let newestTradeIdCheck = await getNewestTradeIdBeforeSubmission()
            console.log(newestTradeIdCheck)
            console.log({
                clientId,
                clientName,
                tradeTicketID,
                tradingPair,
                action,
                spread,
                amount,
                spotRate: formattedSpotRate,
                clientDealtRate: formattedDealtRate,
                amountToTrade,
                ccyDeposited,
                ccyPurchased,
                date: curDate,
                sender: curUser.nickname,
                email
            });
            let result = await submittedTradeTicket({
                clientId,
                clientName,
                tradeTicketID,
                tradingPair,
                action,
                spread,
                amount,
                spotRate: formattedSpotRate,
                clientDealtRate: formattedDealtRate,
                amountToTrade,
                ccyDeposited,
                ccyPurchased,
                date: curDate,
                sender: curUser.nickname,
                liquidityProvider: liquidityProviders,
                amt: execAmt,
                execPrice,
                platform,
                orderId,
                email,
                pushToExecChat: !notPushToExecChat
            });
            if (result){
                setShowPreview(false)
                setUpdate(count)
                setAskingTermsSelect('Fiat')
                setClientName('')
                setTradeTicketID('')
                setTradingPair('BTC/USD')
                setAction('Buy')
                setAmount('0')
                setAmountToTrade('0')
                setSpread('0')
                setDynamicSpread('0')
                setSpotRate('0')
                setClientDealtRate('0')
                setEnableFeeChange(false)
                setLiquidityProvider([])
                setExecPrice('0')
                setPlatform('')
                setOrderId('')
                setClient({})
                setEmail('false')
                setNotPushToExecChat(false)
            }
            setSubmitLoading(false)
          // ...Reset state...
        }
    };

    const fetchTradeData = async () => {
        // First, check if tradeID is present
        if (!tradeID) {
          alert('TradeID required');
          return; 
        }
      
        try {
          const data = await clientService.getAdamDeletedTrades(tradeID);
          setPreviousTradeData(data); // Assuming the fetched data is directly usable
          setShowCancelModal(true);
        } catch (error) {
            alert('TradeID not found')
          console.error("Failed to fetch trade data:", error);
          // Handle the error (e.g., show an error message)
        }
      };

      
      const handleSubmitCancelTrade = async() => {
        // console.log('handleSubmit running');
        setSubmitLoading(true)
        setShowCancelModal(false)
        setTradeID('')
        let count = update + 1
        // console.log('clientName', clientName);
        // console.log('clientOptions', clientOptions);
        if (!clientName) {// && !clientOptions.map(option => option.value).includes(clientName)) {
            setClientNameError('Invalid client name. Please select from the dropdown.');
            return;
        }

        if (clientName) {
            let ccyDeposited = 
                askingTermsSelect === 'Fiat'? 
                    action === 'Buy'? 
                        tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ? 
                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]] ? rates[tradingPair.split('/')[1]].decimal : 5}) + ' ' + tradingPair.split('/')[1]
                        :tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? 
                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
                    : action === 'Buy'? 
                        tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ?
                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 5}) + ' ' + tradingPair.split('/')[1]
                        :tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? 
                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5}) + ' ' + tradingPair.split('/')[0]
            let ccyPurchased =
                askingTermsSelect === 'Fiat'? 
                    action === 'Buy'? 
                        tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal: 8}) + ' ' + tradingPair.split('/')[0]
                        : tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8}) + ' ' + tradingPair.split('/')[1]
                    : action === 'Buy'? 
                        tradingPair.split('/')[0] === 'USDT' || tradingPair.split('/')[0] === 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8}) + ' ' + tradingPair.split('/')[0]
                        : tradingPair.split('/')[1] === 'USDT' || tradingPair.split('/')[1] === 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[1]]? rates[tradingPair.split('/')[1]].decimal : 8}) + ' ' + tradingPair.split('/')[1]
            
            
            let formattedSpotRate = askingTermsSelect === 'Fiat'? 
                parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let formattedDealtRate = askingTermsSelect === 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimals, maximumFractionDigits: dealtDecimals})
                :parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace})
            let liquidityProviders = liquidityProvider.join('+')
            let execAmt = action === 'Buy'? ccyPurchased:ccyDeposited
            let curDate = dayjs(tradeTimestamp).tz("America/New_York").toISOString();
            // console.log('tradeTimestamp', tradeTimestamp);
            // console.log('curDate', curDate);
            // console.log('ccyDeposited', ccyDeposited);
            // console.log('ccyPurchased', ccyPurchased);
            // console.log('curDate', curDate);
            console.log('Running submittedTradeTicket');

            const data = await clientService.getAdamDeletedTrades(tradeID)
            console.log('------THIS IS THE OLD TRADE TICKET-------', data)
            // ADDED FINAL CHECK FOR MOST UPDATED TRADE TICKET ID
            let newestTradeIdCheck = await getNewestTradeIdBeforeSubmission()
            console.log(newestTradeIdCheck)
            console.log({
                clientId,
                clientName,
                tradeTicketID,
                tradingPair,
                action,
                spread,
                amount,
                spotRate: formattedSpotRate,
                clientDealtRate: formattedDealtRate,
                amountToTrade,
                ccyDeposited,
                ccyPurchased,
                date: curDate,
                sender: curUser.nickname,
                email
            });
            let result = await submittedCanceldTradeTicket({
                clientId,
                clientName,
                tradeTicketID,
                tradingPair,
                action,
                spread,
                amount,
                spotRate: formattedSpotRate,
                clientDealtRate: formattedDealtRate,
                amountToTrade,
                ccyDeposited,
                ccyPurchased,
                date: curDate,
                sender: curUser.nickname,
                email,
                liquidityProvider: liquidityProviders,
                amt: execAmt,
                execPrice,
                platform,
                orderId,
                pushToExecChat: !notPushToExecChat
            });
            if(result){
                setShowPreview(false)
                setUpdate(count)
                setAskingTermsSelect('Fiat')
                setClientName('')
                setTradeTicketID('')
                setTradingPair('BTC/USD')
                setAction('Buy')
                setAmount('0')
                setAmountToTrade('0')
                setSpread('0')
                setDynamicSpread('0')
                setSpotRate('0')
                setClientDealtRate('0')
                setEnableFeeChange(false)
                setLiquidityProvider([])
                setExecPrice('0')
                setPlatform('')
                setOrderId('')
                setClient({})
                setNotPushToExecChat(false)
                setTradeID('')
                setEmail('false')
            }
            setSubmitLoading(false)
          // ...Reset state...
        }
    };

    /**
     * FOR TESTING: A useEffect hook that logs the values of various variables whenever they change.
     */
    useEffect(() => {
        console.log('inventoryLowWarning', inventoryLowWarning);
        console.log('assetOut', assetOut);
        console.log('assetInventory', assetInventory);
        console.log('amountToTrade', amountToTrade);
        console.log('check', (inventoryLowWarning || (assetOut && assetInventory <= 0)));
    },[inventoryLowWarning, assetOut, assetInventory, amountToTrade])
    // console.log('tradingPair', tradingPair);
    const handleDateTimeChange = (date) => {
        // Handle the change event here
        console.log(date);
        setTradeTimestamp(date)
    };
    return (
        <Paper style={{padding: 5}}>
            {!showTable ? <Typography variant="button" gutterBottom>Trade ticket generator</Typography> : null}
            <div>
                <Stack spacing={1}>
                    <Stack direction={{xs: 'column', sm: 'row'}} style={{justifyContent: 'space-between'}}>
                    <Stack style={{
                        width: {
                            xs: '100%', // 100% width on xs screens
                            sm: '70%',  // 30% width on sm screens and above
                        }
                    }} spacing={1}>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Direction</div>
                            <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={action}
                                    onChange={(e) => setAction(e.target.value)} required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    <MenuItem value={'Buy'}>Buy</MenuItem>
                                    <MenuItem value={'Sell'}>Sell</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{display: 'flex', alignItems: 'center'}}>Entity</div>
                            <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={entity}
                                    onChange={(e) => setEntity(e.target.value)} required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    <MenuItem value={'SDM'}>SDM</MenuItem>
                                    <MenuItem value={'GL'}>GL</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Client</div>
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={clientOptions}
                                    sx={{width: '100%'}}
                                    size='small'
                                    renderOption={(props, option) => (
                                        <Box component="li" key={option.id} sx={{textAlign: 'left' }} {...props}>
                                            {option.label}
                                        </Box>
                                    )}
                                    filterOptions={(options, { inputValue }) => {
                                        const inputValueLowerCase = inputValue.trim().toLowerCase();
                                        let result = options.filter(
                                            (option) => option.label.trim().toLowerCase().includes(inputValueLowerCase)
                                            );
                                            // Sort the results to have exact matches at the beginning
                                            result.sort((a, b) => {
                                                const aStartsWith = a.label.toLowerCase().startsWith(inputValueLowerCase) ? -1 : 0;
                                                const bStartsWith = b.label.toLowerCase().startsWith(inputValueLowerCase) ? -1 : 0;

                                                return aStartsWith - bStartsWith;
                                            });
                                            return result
                                    }}
                                    value={clientName}
                                    // getOptionLabel={(option) => option.label}
                                    onChange={(e, v) => {
                                        // console.log('v', v)
                                        setClient(v)
                                        setClientName(v.label)
                                        setClientId(v.value)
                                    }}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder="Client Name"/>}
                                />
                            </FormControl>
                            <div style={{display: 'flex', alignItems: 'center'}}>is asking in terms of</div>
                            <FormControl sx={{ m: 1, minWidth: 90 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={askingTermsSelect}
                                    onChange={(e) => setAskingTermsSelect(e.target.value)} 
                                    required
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    {askingTermsOptions.map((option) => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Trading Pair</div>
                            <FormControl sx={{ m: 1, minWidth: 140 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={tradingPairOptions}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={tradingPair}
                                    freeSolo
                                    filterOptions={(options, state) => {
                                        // custom filter function; checks if the *beginning*
                                        // of each option (Trading Pair) matches the user input:
                                        const inputValue = state.inputValue.toLowerCase();
                                        const inputLength = inputValue.length;
                                        return options.filter((option) => {
                                            // slicing the optionLabel up to the length of the inputValue
                                            const optionLabel = option.label.toLowerCase().slice(0, inputLength);
                                            // and comparing the two strings.
                                            return optionLabel === inputValue;
                                        });
                                    }}
                                    onInputChange={(e, v) => {
                                        // console.log('input', e);
                                        // console.log('input', v);
                                        setTradingPair(v.toUpperCase())}}
                                    // onChange={(e, v) => {
                                    //     console.log(v)
                                    //     setTradingPair(v ? v.value : '')
                                    // }} // example of v: {value: 'BTC/CAD', label: 'BTC/CAD'}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder="Assets Pair"/>}
                                />
                            </FormControl>
                            <div style={{display: 'flex', alignItems: 'center'}}>Amount</div>
                            <FormControl sx={{ m: 1, minWidth: 195 }}>
                                <TextField 
                                    value={amount} 
                                    onChange={(e) => {
                                        // console.log('e', e)
                                        setAmount(e.target.value)
                                    }}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[1]:tradingPair.split('/')[0]
                                    }}
                                    required
                                    sx={{width: '100%'}}
                                    size='small'
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Spread/Fee</div>
                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <TextField 
                                    value={spreadLoading ? 'Loading...' : feeType === 0 ? spread: dynamicSpread} 
                                    readOnly={spreadLoading} 
                                    onChange={(e) => setSpread(e.target.value)} 
                                    error={spreadError}
                                    helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                    required
                                    size='small'
                                    disabled={!enableFeeChange}
                                    InputProps={{
                                        endAdornment: '%'
                                    }}
                                />
                            </FormControl>
                            <Button onClick={() => handleEnableFeeChangeDialog()}>Change Fee</Button>
                            {/* <Button onClick={() => handleChangeFeeType()} disabled={true} color="secondary">{feeType === 0 ? 'Use Dynamic Fee' : 'Use Default'}</Button> */}
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Spot Rate</div>
                            <FormControl sx={{ m: 1, minWidth: 160 }}>
                                <TextField 
                                    value={parseFloat(spotRate).toFixed(dealtDecimals)} 
                                    // onChange={(e) => {
                                    //     setSpotRate(e.target.value)
                                    // }}
                                    onFocus={handleSpotFocus}
                                    onBlur={handleSpotBlur}
                                    onChange={handleSpotRateChange}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                            <div style={{display: 'flex', alignItems: 'center'}}>Dealt Rate</div>
                            <FormControl sx={{ m: 1, minWidth: 160 }}>
                                <TextField 
                                    value={parseFloat(clientDealtRate).toFixed(dealtDecimals)}
                                    // disabled
                                    // onChange={(e) => {
                                    //     setClientDealtRate(e.target.value)
                                    // }}
                                    onFocus={handleDealtFocus}
                                    onBlur={handleDealtBlur}
                                    onChange={handleDealtRateChange}
                                    required
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Amount to Trade</div>
                            <FormControl sx={{ m: 1, minWidth: 160 }}>
                                <TextField 
                                    value={Number(amountToTrade).toFixed(decimalPlace)} 
                                    disabled
                                    required
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                    }}
                                />
                            </FormControl>
                            <div style={{display: 'flex', alignItems: 'center'}}>Trade ID</div>
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                                <TextField 
                                    value={tradeTicketID}
                                    disabled
                                    required
                                    size='small'
                                    sx={{width: '100%'}}
                                    // fullWidth
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                    <Stack sx={{ justifyContent: 'center', display: {
                            sm: 'none'
                        }}}>
                        <FormControlLabel control={<Checkbox checked={notPushToExecChat} onChange={handleNotPushExecChange}/>} label="Don't push trade to Exec"/>
                    </Stack>
                    <Stack sx={{ m: 2, display: {
                            // xs: 'block', 
                            sm: 'none'
                        }}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                    <Stack style={{
                        width: {
                            xs: '100%', // 100% width on xs screens
                            sm: '30%',  // 30% width on sm screens and above
                        }
                    }} spacing={1}>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>LP</div>
                            <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={liquidityProvider}
                                    onChange={handleLiquidityProviderChange} required
                                    multiple
                                    displayEmpty
                                    // input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%', maxWidth: '250px'}}
                                    size='small'
                                >
                                    <MenuItem value={'cross'}>
                                        <Checkbox checked={liquidityProvider.indexOf('cross') > -1} />
                                        <ListItemText primary={'cross'} />
                                    </MenuItem>
                                    <MenuItem value={'aquanow'}>
                                        <Checkbox checked={liquidityProvider.indexOf('aquanow') > -1} />
                                        <ListItemText primary={'aquanow'} />
                                    </MenuItem>
                                    <MenuItem value={'b2c2'}>
                                        <Checkbox checked={liquidityProvider.indexOf('b2c2') > -1} />
                                        <ListItemText primary={'b2c2'} />
                                    </MenuItem>
                                    <MenuItem value={'cumberland'}>
                                        <Checkbox checked={liquidityProvider.indexOf('cumberland') > -1} />
                                        <ListItemText primary={'cumberland'} />
                                    </MenuItem>
                                    <MenuItem value={'dvchain'}>
                                        <Checkbox checked={liquidityProvider.indexOf('dvchain') > -1} />
                                        <ListItemText primary={'dvchain'} />
                                    </MenuItem>
                                    <MenuItem value={'enigma'}>
                                        <Checkbox checked={liquidityProvider.indexOf('enigma') > -1} />
                                        <ListItemText primary={'enigma'} />
                                    </MenuItem>
                                    <MenuItem value={'flowtraders'}>
                                        <Checkbox checked={liquidityProvider.indexOf('flowtraders') > -1} />
                                        <ListItemText primary={'flowtraders'} />
                                    </MenuItem>
                                    <MenuItem value={'galaxy'}>
                                        <Checkbox checked={liquidityProvider.indexOf('galaxy') > -1} />
                                        <ListItemText primary={'galaxy'} />
                                    </MenuItem>
                                    <MenuItem value={'mobilum'}>
                                        <Checkbox checked={liquidityProvider.indexOf('mobilum') > -1} />
                                        <ListItemText primary={'mobilum'} />
                                    </MenuItem>
                                    <MenuItem value={'nonco'}>
                                        <Checkbox checked={liquidityProvider.indexOf('nonco') > -1} />
                                        <ListItemText primary={'nonco'} />
                                    </MenuItem>
                                    <MenuItem value={'wintermute'}>
                                        <Checkbox checked={liquidityProvider.indexOf('wintermute') > -1} />
                                        <ListItemText primary={'wintermute'} />
                                    </MenuItem>
                                    <MenuItem value={'falconx'}>
                                        <Checkbox checked={liquidityProvider.indexOf('falconx') > -1} />
                                        <ListItemText primary={'falconx'} />
                                    </MenuItem>
                                    <MenuItem value={'stillmandigital'}>
                                        <Checkbox checked={liquidityProvider.indexOf('stillmandigital') > -1} />
                                        <ListItemText primary={'stillmandigital'} />
                                    </MenuItem>
                                    <MenuItem value={'crypto.com'}>
                                        <Checkbox checked={liquidityProvider.indexOf('crypto.com') > -1} />
                                        <ListItemText primary={'crypto.com'} />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Amt</div>
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                                <TextField 
                                    value={
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5})
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 8, minimumFractionDigits: rates[tradingPair.split('/')[0]]? rates[tradingPair.split('/')[0]].decimal : 5})
                                        }
                                    disabled
                                    required
                                    size='small'
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair.split('/')[0]
                                    }}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Price</div>
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                                <TextField 
                                    value={execPrice} 
                                    onChange={(e) => {
                                        setExecPrice(e.target.value)
                                    }}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom,
                                        endAdornment: tradingPair
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>Platform</div>
                            <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={platform}
                                    onChange={(e) => setPlatform(e.target.value)} required
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{widht: '100%'}}
                                    size='small'
                                >
                                    <MenuItem value={'SDMLive'}>SDMLive</MenuItem>
                                    <MenuItem value={'AXE'}>AXE</MenuItem>
                                    <MenuItem value={'Telegram'}>Telegram</MenuItem>
                                    <MenuItem value={'Signal'}>Signal</MenuItem>
                                    <MenuItem value={'Whatsapp'}>Whatsapp</MenuItem>
                                    <MenuItem value={'Email'}>Email</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>OrderId</div>
                            <FormControl sx={{ m: 1, minWidth: 170 }}>
                                <TextField 
                                    value={orderId} 
                                    onChange={(e) => {
                                        setOrderId(e.target.value)
                                    }}
                                    required
                                    size='small'
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={1} width={'100%'} sx={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Date</div>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker 
                                        // label="Basic date time picker" 
                                        slotProps={{ textField: { size: 'small', hiddenLabel: true } }}
                                        onChange={handleDateTimeChange}
                                        value={tradeTimestamp}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Stack>
                    </Stack>
                    </Stack>
                    {assetOut? <Stack sx={{ m: 2}}>
                        Available Inventory for {assetOut}: {assetInventory} 
                    </Stack>: null }
                    <Stack sx={{ m: 10,justifyContent: 'center', alignItems: 'center',display: {
                            xs: 'none', 
                            sm: 'flex'
                        }}}>
                        <FormControlLabel control={<Checkbox checked={notPushToExecChat} onChange={handleNotPushExecChange}/>} label="Don't push trade to Exec"/>
                        <FormControlLabel
                    sx={{ width: '150px', margin: 'auto'}}
                        control={
                            <Checkbox
                            name="noEmail"
                            checked={email === 'true'}
                            onChange={(e) => {
                                setEmail(e.target.checked ? 'true' : 'false');
                                console.log("This is the current state", e.target.checked);
                            }}
                            />
                        }
                        label="No Email"
                        />
                    </Stack>
                    
                    
                    <Stack sx={{ m: 2, display: {
                            xs: 'none', 
                            sm: 'contents'
                        }}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                </Stack>
                <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, marginTop: '1em'}}>
                        <label>Review & Replace</label>
                        <FormControl sx={{display: 'flex'}}>
                            <TextField
                                size='small'
                                sx={{width: '100%'}}
                                onChange={(e) => setTradeID(e.target.value)}
                                placeholder='Enter TradeID'
                                value={tradeID}
                            > 
                            {console.log(tradeID)}
                            </TextField>
                            
                        </FormControl>
                        <Button
                            sx={{bgcolor: '#FFC32C', color: 'black', padding: '0.5em 2em'}}
                            onClick={fetchTradeData}
                            >
                            Cancel or Supersede
                        </Button>
                    </Stack>
            </div>
            {/* {showTable ?  */}
                <Stack style={{marginTop: '5px'}}>
                    <div style={{margin: 10}}>
                        <LoadingButton loading={downloading} variant="contained" onClick={handleDownloadExcel}>
                            download compliance
                        </LoadingButton>
                        {/* <button onClick={handleDownloadExcel}>download compliance</button> */}
                    </div>
                    <TradeTicketsTable
                        accessToken={token}
                        tradesData={rawTrade}
                        loading={loading}
                        curUser={curUser}
                        update={update}
                        setUpdate={setUpdate}
                        setErrorMsg={setErrorMsg}
                        setIsError={setIsError}
                        setSuccessMsg={setSuccessMsg}
                        setIsSuccess={setIsSuccess}
                        paginationProps={paginationProps}
                        clientOptions={clientOptions && clientOptions}
                    />
                </Stack>
                {/* :null} */}
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
                <Alert severity="error" onClose={() => setIsError(false)}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
                <Alert severity="success" onClose={() => setIsSuccess(false)}>
                    {successMsg}
                </Alert>
            </Snackbar>
            {/* <Snackbar 
                open={inventoryLowWarning || (assetOut && assetInventory <= 0)} 
                autoHideDuration={2000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            >
                <Alert severity="error">
                    You don't have enought inventory to submit this trade!
                </Alert>
            </Snackbar> */}
            <TradePreviewDialog 
                open={showPreview} 
                submitTicket={(e) => handleSubmit(e)}
                handleClose={() => {setShowPreview(false); setSubmitLoading(false)}}
                clientName={clientName}
                tradeTicketID={tradeTicketID}
                tradingPair={tradingPair}
                action={action}
                spread={spread}
                amount={amount}
                spotRate={spotRate}
                clientDealtRate={clientDealtRate}
                amountToTrade={amountToTrade}
                askingTermsSelect={askingTermsSelect}
                date={dayjs(tradeTimestamp).format('DD-MMM-YY')}
                decimalPlace={decimalPlace}
                dealtDecimal={dealtDecimals}
                loading={submitLoading}
                liquidityProvider={liquidityProvider.join('+')}
                execAmt={askingTermsSelect == 'Fiat'? 
                parseFloat(amountToTrade)
                :parseFloat(amount)}
                execPrice={execPrice}
                platform={platform}
                orderId={orderId}
                email={email}
                notPushToExecChat={notPushToExecChat}
                handleNotPushExecChange={handleNotPushExecChange}
            />
            <CancelTradeModal
                open={showCancelModal}
                submitTicket={(e) => handleSubmitCancelTrade(e)}
                handleClose={() => setShowCancelModal(false)}
                previousTradeData={previousTradeData} 
                clientName={clientName}
                tradeTicketID={tradeTicketID}
                tradingPair={tradingPair}
                action={action}
                spread={spread}
                amount={amount}
                spotRate={spotRate}
                clientDealtRate={clientDealtRate}
                amountToTrade={amountToTrade}
                askingTermsSelect={askingTermsSelect}
                date={dayjs(tradeTimestamp).format('DD-MMM-YY')}
                decimalPlace={decimalPlace}
                dealtDecimal={dealtDecimals}
                loading={submitLoading}
                liquidityProvider={liquidityProvider.join('+')}
                execAmt={askingTermsSelect == 'Fiat'? 
                parseFloat(amountToTrade)
                :parseFloat(amount)}
                execPrice={execPrice}
                platform={platform}
                orderId={orderId}
                email={email}
                />
            <Modal
                open={feeChangeAlert}
                onClose={() => setFeeChangeAlert(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                        Confirm to change fee
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    </Typography>
                    <Stack direction={'row'} spacing={1} sx={{justifyContent: 'center'}}>
                        <Button variant='contained' color='primary' onClick={() =>  handleConfirmEnableFeeChange()}>Confirm</Button>
                        <Button variant='contained' color='error' onClick={() =>  setFeeChangeAlert(false)}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal>
        </Paper>
    );
}

// export default TradeTicketGenerator;
export default withAuthenticationRequired(TradeTicketGenerator, {
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});