import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EditFeeModal from './EditFeeModal';
import AddWalletModal from './AddWalletModal';
import AddClientnameModal from './AddClientnameModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SimpleTablePagination from '../../../components/SimpleTablePagination';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { Button, TextField, Autocomplete, FormControl, CircularProgress, Select, MenuItem, Box, InputLabel } from '@mui/material';
import { ClientService } from '../../../api/ClientService';
import CustomizedMultiSelect from '../../../components/CustomizedMultiSelect';
import { queue } from 'async'
import AdjustBalancesModal from './AdjustBalancesModal';
import { CheckBox } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import EditEmailModal from './EditEmailModal';
import "../ClientMapping/dropdown.css"


const ENTITIES_LIST =['SDM', 'GL']
const BANK_LIST = ['BCB', 'ConnectFirst', 'Finclusive', 'Fortress', 'Openpayd', 'PrimeTrust', 'Arival']

/**
 * Creates a throttled function that limits the number of concurrent requests made to the server.
 * @param {Function} task - The function to be executed for each request.
 * @param {number} limit - The maximum number of concurrent requests allowed.
 * @returns A throttled function that can be used to make requests to the server.
 */
const settledStatusRequestThrottler = queue(({ task, abortController }, completed) => {
    task(abortController).then(() => completed())
        .catch(err => console.error(err))
}, 3)

/**
 * A React functional component that displays the settled or unsettled status of a client's balance.
 * @param {Object} props - The component props.
 * @param {string} props.clientCopperId - The client's Copper ID.
 * @param {Object} props.clientService - The client service object.
 * @returns The JSX element representing the settled or unsettled status.
 */
function SettledStatus({ clientCopperId, clientService }) {
    const [ loading, setLoading ] = useState(true)
    const [ balanceData, setBalanceData ] = useState()

    const loadStatus = async abortController => {

        // Not sure why the try-catch within the getClientBalanceData isn't handling the abort error
        try {
            const balances = await clientService.getClientBalanceData(clientCopperId, abortController)

            setBalanceData(balances)
            setLoading(false)
        }
        catch(err) {
            if(err.name === 'AbortError') {
                console.log('Aborted GET request for settled/unsettled status')
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController()

        settledStatusRequestThrottler.push({
            abortController,
            task: loadStatus
        }, error => {
            if(error) {
                console.error(error)
                return
            }
        })
        
        return () => {
            abortController.abort()
            // settledStatusRequestThrottler.remove()
        }
    }, [clientCopperId])
    
    if(loading) {
        return <CircularProgress />
    }

    // Safe to compare with 0 since amount was calculated using big decimal
    const settled = Object.values(balanceData?.balances ?? {}).every(amount => parseFloat(amount.replace(/[^0-9.-]+/g, '')) === 0.0)

    return settled ? (
        <b>Settled</b>
    )
    :
    (
        <Stack direction='column'>
            <b>Unsettled</b>
            {
                Object.entries(balanceData.balances)
                    .filter(([ _, amount ]) => parseFloat(amount.replace(/[^0-9.-]+/g, '')) !== 0.0)
                    .map(([ asset, amount ]) => (
                        <b>{amount} {asset}</b>
                    ))
            }
        </Stack>
    )
}

function Tasks({ tasks, deleteClient, feeEdited, walletAdded, clientnameAdded, loading, update, setUpdate, accessToken, setDeletedWallet, balanceUpdate, setBalanceUpdate, paginationProps, clientFilter, setClientFilter, balancesDownloading, downloadBalances, searchResults, showBalanceDatePicker, setShowBalanceDatePicker, balancesDate, setBalancesDate, clientCopperID, show, onClose }) {
    const [isEditing, setIsEditing] = useState(-1);
    const [clientOnEdit, setClientOnEdit] = useState();
    const [isEditingBank, setIsEditingBank] = useState(-1);
    const [selectedBanks, setSelectedBanks] = useState([])
    const clientService = new ClientService(accessToken);
    const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState (false);
    const [selectedClientCopperId, setSelectedClientCopperId] = useState(null);

    const [isEditFeeModalOpen, setIsEditFeeModalOpen] = useState(false);
    const [isAddWalletModalOpen, setAddWalletModalOpen] = useState(false)
    const [isAddNameModalOpen, setIsAddNameModalOpen] = useState(false)
    const [isAddBalanceModalOpen, setIsAddBalanceModalOpen] = useState(false)
    const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false)
    const [modalDefault, setModalDefault] = useState('Select Action')
    useEffect(() => () => settledStatusRequestThrottler.kill(), [])


    const handleEditClientEntity = (index, client) => {
        setIsEditing(index)
        setClientOnEdit(client)
        //console.log(ENTITIES_LIST.includes(clientOnEdit.entityForClient))
    }

    const handleClientEntityOnChange = (e, newValue) => {
        // console.log(newValue)
        let client = {...clientOnEdit}
        client.entityForClient = newValue.join(', ')
        setClientOnEdit(client)
    }

    const handleCloseEditingClientEnity = () => {
        setIsEditing(-1)
    }

    const handleEditClientBanks = (index, client) => {
        setIsEditingBank(index)
        setClientOnEdit(client)
        let banks = client.adamClientBanks.map(bank => bank.bank)
        // console.log(banks)
        setSelectedBanks(banks)
    }

    const handleCloseEditingClientBanks = () => {
        setIsEditingBank(-1)
        setSelectedBanks([])
    }

    /**
     * Updates the client entity with the given ID and new entity data.
     */
    const onConfirmUpdateClientEntity = async(clientId, newEntity) => {
        try{
            let counter = update + 1
            let result = await clientService.updateClientEnity(clientId, newEntity)
            // console.log(result);
            if(result[0] === 1) {
                setUpdate(counter)
                handleCloseEditingClientEnity()
            }
            else {
                // add alert
            }
            
        }
        catch (err){
            console.log(err)
            // add alert
        }
        
    }

    /**
     * Updates the client banks for a given client ID with the selected banks.
     */
    const onConfirmUpdateClientBanks = async(clientId, existingBanks) => {
        // console.log(existingBanks)
        // console.log(selectedBanks)

        // let removedBanks = existingBanks.filter(eBanks => !selectedBanks.includes(eBanks.bank))
        // console.log(removedBanks)
        // let existedBank = existingBanks.filter(eBanks => !selectedBanks.includes(eBanks.bank))
        try{
            let counter = update + 1
            let result = await clientService.updateClientBanks(clientId, selectedBanks, existingBanks)
            // console.log(result)
            if(result) {
                setUpdate(counter)
                handleCloseEditingClientBanks()
            }
            else {
                // add alert
            }
            
        }
        catch (err){
            console.log(err)
            // add alert
        }
    }

    const handleClientBankSelectionChange = (e, value) => {
        // console.log(value)
        setSelectedBanks(value)
    }

    const TaskRow = (task, index) => {

    const [selectedModal, setSelectedModal] = useState("Select Action");

    const resetSelectedModal = () => {
      setSelectedModal("Select Action"); // Reset to the default state
  };


    // Change modal based on value selected
    const handleModalChange = (event, task) => {
      setSelectedModal(event.target.value);

      switch (event.target.value) {
        case "editFee":
          setClientOnEdit(task);
          setIsEditFeeModalOpen(true);
          break;
        case "addWallet":
          setClientOnEdit(task);
          setAddWalletModalOpen(true);
          break;
        case "addName":
          setClientOnEdit(task);
          setIsAddNameModalOpen(true);
          break;
        case "addBalance":
          setClientOnEdit(task);
          setIsAddBalanceModalOpen(true);
          break;
        case "editEmail":
          setIsAddEmailModalOpen(true);
          break;
        default:
          setModalDefault('Select Action')
          break;
      }
    };


        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
            <TableCell>{task.clientCopperId}</TableCell>
            <TableCell align="left">
              <Link to={`/clientDetails/${task.clientCopperId}`}>
                {task.clientPrimaryName}
              </Link>
            </TableCell>
            <TableCell align="left">
              {task.adamClientnames.map((name) => (
                <ul>
                  {name.client_name}{" "}
                  <span style={{ fontSize: "11px", color: "gray" }}>
                    ({name.name_source})
                  </span>
                </ul>
              ))}
            </TableCell>
            {/* <TableCell align='left'>{task.clientCopperId}</TableCell> */}
            <TableCell align="left">
              <Stack direction={"row"} spacing={1}>
                {isEditing === index ? (
                  <FormControl sx={{ minWidth: 150 }}>
                    <CustomizedMultiSelect
                      defaultValues={
                        clientOnEdit.entityForClient
                          ? clientOnEdit.entityForClient
                              .split(", ")
                              .filter((str) => str !== "")
                          : []
                      }
                      options={ENTITIES_LIST}
                      onChange={(e, v) => handleClientEntityOnChange(e, v)}
                    />
                    {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={ENTITIES_LIST}
                                    sx={{width: '100%'}}
                                    size='small'
                                    value={clientOnEdit.entityForClient}
                                    onChange={(e, v) => handleClientEntityOnChange(v)}
                                    renderInput={(params) => 
                                    <TextField {...params} placeholder={task.entityForClient}/>}
                                /> */}
                  </FormControl>
                ) : (
                  <div style={{ display: "flex", alignItems: "center", }}>
                    {task.entityForClient || 'N/A'}
                  </div>
                )}
                {isEditing === index ? (
                  <Stack direction={"row"}>
                    <Button
                      onClick={() =>
                        onConfirmUpdateClientEntity(
                          clientOnEdit.id,
                          clientOnEdit.entityForClient
                        )
                      }
                    >
                      Save
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleCloseEditingClientEnity()}
                    >
                      Cancel
                    </Button>
                  </Stack>
                ) : (
                  <Button onClick={() => handleEditClientEntity(index, task)}>
                    Edit
                  </Button>
                )}
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack direction={"row"} spacing={1}>
                {isEditingBank === index ? (
                  <FormControl sx={{ minWidth: 150 }}>
                    <CustomizedMultiSelect
                      defaultValues={selectedBanks}
                      options={BANK_LIST}
                      onChange={(e, v) => handleClientBankSelectionChange(e, v)}
                    />
                  </FormControl>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {task.adamClientBanks
                      .map((bankObj) => bankObj.bank)
                      .join(", ") || "N/A"}
                  </div>
                )}
                {isEditingBank === index ? (
                  <Stack direction={"row"}>
                    <Button
                      disabled={
                        task.adamClientBanks.map((bankObj) => bankObj.bank)
                          .length === 0 && selectedBanks.length === 0
                      }
                      onClick={() =>
                        onConfirmUpdateClientBanks(
                          clientOnEdit.id,
                          clientOnEdit.adamClientBanks
                        )
                      }
                    >
                      Save
                    </Button>
                    <Button
                      color="error"
                      onClick={() => handleCloseEditingClientBanks()}
                    >
                      Cancel
                    </Button>
                  </Stack>
                ) : (
                  <Button onClick={() => handleEditClientBanks(index, task)}>
                    Edit
                  </Button>
                )}
              </Stack>
            </TableCell>
            <TableCell align="left">
              {task.adamClientFee
                ? parseFloat(task.adamClientFee.fee) + "%"
                : ""}
            </TableCell>
            <TableCell align="left">
              <SettledStatus
                clientCopperId={task.clientCopperId}
                clientService={clientService}
              />
            </TableCell>
            {/* <TableCell align='left'>{task.adamClientnames.length}</TableCell>
                <TableCell align='left'>{task.adamWallets.length}</TableCell> */}
            <TableCell align="left">
              <Stack spacing={1}>
                <FormControl
                className='client-mapping-select'
                >
                <Select
                  labelId="modal-select-label"
                  id="modal-select"
                  value={selectedModal}
                  displayEmpty
                  
                  label="Select Modal"
                  onChange={(event) => handleModalChange(event, task)}
                  style={{
                    border: "1px solid gold",
                    borderRadius: "1px",
                    outline: "none",
                    height: "40px",
                    boxShadow: "none",
                    "& hover": "none",
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={modalDefault} disabled>
                    Select Action
                  </MenuItem>
                  <MenuItem value="editFee">Edit Fee</MenuItem>
                  <MenuItem value="addWallet">Add Wallet</MenuItem>
                  <MenuItem value="addName">Add Name</MenuItem>
                  <MenuItem value="addBalance">Add Balance</MenuItem>
                  <MenuItem value="editEmail">Edit Email</MenuItem>
                </Select>
                </FormControl>
               
                  
                {selectedModal === "editFee" && (
                  <EditFeeModal
                    adamClient={task}
                    feeEdited={feeEdited}
                    update={update}
                    setUpdate={setUpdate}
                    accessToken={accessToken}
                    onClose={resetSelectedModal}
                  />
                )}
                {selectedModal === "addWallet" && (
                  <AddWalletModal
                    adamClient={task}
                    walletAdded={walletAdded}
                    setDeletedWallet={setDeletedWallet}
                    update={update}
                    setUpdate={setUpdate}
                    accessToken={accessToken}
                    onClose={resetSelectedModal}
                  />
                )}

                {selectedModal === "addName" && (
                  <AddClientnameModal
                    adamClient={task}
                    clientnameAdded={clientnameAdded}
                    update={update}
                    setUpdate={setUpdate}
                    accessToken={accessToken}
                    onClose={resetSelectedModal}
                  />
                )}
                {selectedModal === "addBalance" && (
                  <AdjustBalancesModal
                    adamClient={task}
                    accessToken={accessToken}
                    balanceUpdate={balanceUpdate}
                    setBalanceUpdate={setBalanceUpdate}
                    onClose={resetSelectedModal}
                  />
                )}
                {selectedModal === "editEmail" && (
                  <EditEmailModal
                    adamClient={task}
                    accessToken={accessToken}
                    clientCopperID={task.clientCopperId}
                    onClose={resetSelectedModal}
                    
                  />
                )}
              </Stack>
            </TableCell>
          </TableRow>
        );
    }
    const clientTable = tasks.map((task,index) => TaskRow(task,index))
    if (tasks.length === 0) return (
        <Select
            autoWidth
            value={clientFilter}
            onChange={e => setClientFilter(e.target.value)}
        >
            <MenuItem value={'ALL'}>All Clients</MenuItem>
            <MenuItem value={'SETTLED'}>Settled Clients</MenuItem>
            <MenuItem value={'UNSETTLED'}>Unsettled Clients</MenuItem>
        </Select>
    )
    else return (
        <>
            <Paper>
                <TableContainer sx={{ maxHeight: "100%", width: '100%', margin: 'auto' }}>
                    {loading ? <LinearProgress color="success"/> : null}
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Copper ID</TableCell>
                                <TableCell align="left">Client's Primary Name</TableCell>
                                <TableCell align="left">Client's Alternate Names</TableCell>
                                {/* <TableCell align="left">Client's Copper ID</TableCell> */}
                                <TableCell align="left">Our Entity for Client</TableCell>
                                <TableCell align="left">Bank</TableCell>
                                <TableCell align="left">Client's Commission Fee</TableCell>
                                {/* <TableCell align="left">Alternate Names</TableCell>
                                <TableCell align="left">Wallets</TableCell> */}
                                <TableCell align="left">Settled/Unsettled</TableCell>
                                <TableCell align="left">Actions</TableCell>    
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientTable}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack direction={'row'} alignContent={'space-between'} gap={2}>
                    <SimpleTablePagination paginationProps={paginationProps} />
                    <Select
                        autoWidth
                        value={clientFilter}
                        onChange={e => setClientFilter(e.target.value)}
                    >
                        <MenuItem value={'ALL'}>All Clients</MenuItem>
                        <MenuItem value={'SETTLED'}>Settled Clients</MenuItem>
                        <MenuItem value={'UNSETTLED'}>Unsettled Clients</MenuItem>
                    </Select>
                    <Stack direction={'row'}>
                        <Select
                            autoWidth
                            value={showBalanceDatePicker ? 'SHOW' : 'HIDE'}
                            onChange={e => setShowBalanceDatePicker(e.target.value === 'SHOW')}
                        >
                            <MenuItem value={'HIDE'}>Download All Settled/Unsettled</MenuItem>
                            <MenuItem value={'SHOW'}>Download Settled/Unsettled Until Date</MenuItem>
                        </Select>
                        {
                            showBalanceDatePicker &&
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={dayjs(balancesDate)}
                                    onChange={newDate => setBalancesDate(newDate)}
                                />
                            </LocalizationProvider>
                        }
                        <Button variant='contained' disabled={balancesDownloading} onClick={() => downloadBalances()}>Download (XLSX)</Button>
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
}

export default Tasks;