import React, {useState, useEffect} from 'react';
import { ClientService } from '../../../api/ClientService';
import { Stack, Button, Select, MenuItem, FormControl, TextField, IconButton, Autocomplete, Snackbar, OutlinedInput, Paper, Modal, Box, Typography, InputAdornment } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import { NumericFormat } from 'react-number-format';
import { log } from 'async';
/**
 * A customized version of the Select component from the Material-UI library.
 * @param {object} theme - The theme object provided by the Material-UI theme provider.
 * @returns A styled Select component with customized styles.
 */
const CustomizedSelect = styled(Select)(({ theme }) => ({
    '&:focus': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}));

// = {
//     select: {
//       // Remove the border and outline styles
//         '&:focus': {
//             backgroundColor: 'transparent',
//             boxShadow: 'none',
//         },
//         '& .MuiOutlinedInput-notchedOutline': {
//             border: 'none',
//         },
//     },
// };

/**
 * A custom alert component that forwards the ref to the underlying MuiAlert component.
 * @param {object} props - The props passed to the component.
 * @param {React.Ref} ref - The ref to be forwarded to the underlying MuiAlert component.
 * @returns The rendered MuiAlert component with the provided props and ref.
 */
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
/**
 * A custom input component for formatting numeric values.
 * @param {object} props - The component props.
 * @param {function} props.onChange - The callback function to handle value changes.
 * @param {React.Ref} ref - The ref object for accessing the input element.
 * @returns A React component that formats numeric values.
 */
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

/**
 * A custom React component that wraps the NumericFormat component and forwards the ref.
 * @param {object} props - The component props.
 * @param {function} ref - The ref object.
 * @returns The wrapped NumericFormat component with the forwarded ref.
 */
const NumericCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            //thousandSeparator
            valueIsNumericString
        />
    );
});

function TradeCommission({curUser, token}) {
    const [clientOptions, setClientOptions] = useState([]);
    const [client, setClient] = useState({})
    const [clientName, setClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [commissionStructure, setCommissionStructure] = useState([]);
    const [update, setUpdate] = useState(0)
    // save the previous fee for notification
    const [oldFee, setOldFee] = useState();
    // State for loading and error state of Spread/Fee API call
    const [spreadLoading, setSpreadLoading] = useState(false);
    const [spreadError, setSpreadError] = useState(null);
    const [spread, setSpread] = useState('0');
    const [dynamicSpread, setDynamicSpread] = useState(null);

    const [tradingPairOptions ,setTradingPairOptions] = useState([{value: 'ALL', label: 'ALL'}]);
    const [tradingPair, setTradingPair] = useState('ALL');
    const [tradingVol, setTradingVol] = useState(0);

    const [loading, setLoading] = useState(false)
    const [valueInputError, setValueInputError] = useState(-1)
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const [otherCommissionStructures, setOtherCommissionStructures] = useState([])
    /**
     * Creates a new instance of the ClientService class with the provided token.
     * @param {string} token - The authentication token for the client service.
     * @returns A new instance of the ClientService class.
     */
    const clientService = new ClientService(token);
    useEffect(() => {
        if(tradingPair == 'ALL') {
            getOtherCommissionStrutures(clientId)
        }
    }, [tradingPair, clientId])
    async function getOtherCommissionStrutures(clientId) {
        const otherComms = await clientService.getAssetPairCommissionStructures(clientId);
        console.log(otherComms);
        setOtherCommissionStructures(otherComms)
    }
    // const tradeService = new TradeTicketService(token);
    /**
     * Fetches trading pairs from the API and sets the trading pair options in the component's state.
     */
    useEffect(() => {
        const fetchTradingPairs = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + '/tradingpairs',
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                let tradingPairs = data.map(item => ({
                    value: item.trading_pair,
                    label: item.trading_pair,
                })) //.sort((a, b) => a.value < b.value ? -1 : a.value > b.value ? 1 : 0);
                // console.log();
                tradingPairs.push({value: 'ALL', label: 'ALL'})
                tradingPairs = tradingPairs.sort((a, b) => {
                    // if (a.value === 'All') {
                    //     return -1; // 'All' comes first
                    // } else if (b.value === 'ALL') {
                    //     return 1; // 'All' comes first
                    // } else {
                    //     return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
                    // }
                    if (a.value === 'ALL') {
                        return -1; // 'All' comes first
                    } else if (b.value === 'ALL') {
                        return 1; // 'All' comes first
                    } else {
                        return a.value.localeCompare(b.value); // Sort other elements alphabetically
                    }
                    
                });
                setTradingPairOptions(tradingPairs);
            } catch (error) {
                console.error('There was an error fetching trading pairs!', error);
            }
        };
    
        fetchTradingPairs();
    }, []);

    /**
     * Fetches client data from an API and formats it for autocomplete functionality.
     */
    useEffect(() => {
        // API fetching function
        const getClientNamesFromAPI = async () => {
            // Fetch data from API and return an array of client names
            const clientDataFromAPI = await clientService.getAllClients();
            // console.log(clientDataFromAPI);
            // console.log('clientDataFromAPI', clientDataFromAPI);
            let clientDataForAutocomplete = []; // initialize empty array

            // Loop through the data and format it for our autocomplete
            for (const aClientData of clientDataFromAPI) {
                let the_name = ''; // initialize empty string

                // iterate through aClientData.adamClientnames to look for name_source: 'TradeTicketName'
                for (const aClientname of aClientData.adamClientnames) {
                    if (aClientname.name_source === 'TradeTicketName') {
                        the_name = aClientname.client_name;
                        break;
                    }
                }
                // if we didn't find an adamClientname name_source: 'TradeTicketName', use the primary/Copper name
                if (the_name === '') {
                    the_name = aClientData.clientPrimaryName;
                }
                clientDataForAutocomplete.push({
                    name: the_name,
                    id: aClientData.id,
                    copperId: aClientData.clientCopperId
                });
            }
            // console.log('clientDataForAutocomplete', clientDataForAutocomplete);    
            // return ['Client 1', 'Client 2', 'Client 3']; // good for testing without API data
            return clientDataForAutocomplete;
        };
    
        getClientNamesFromAPI().then(data => {
            const options = data.sort((a,b) => a.name.localeCompare(b.name)).map(client => ({
                value: client.copperId, label: client.name, id: client.copperId, copperId: client.copperId 
            }));
            setClientOptions(options);
        });
    }, []);
    // Effect to fetch Spread/Fee when clientName changes
    useEffect(() => {
        // console.log('client', client);
        // console.log('clientName', clientName);
        if (!clientName) return; // Don't fetch if no client name selected
        setSpreadLoading(true);
        setSpreadError(null);
        clientService.getSpread(client.copperId)
            .then(response => {
                console.log(response)
                // console.log('/api/spread response:', parseFloat(response.fee));
                if(!isNaN(parseFloat(response.fee))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setSpread(parseFloat(response.fee));
                }
                else {
                    console.log('not a number')
                    setSpread("0")
                }
                // get the fee based on trading pair selected
                if(!isNaN(parseFloat(response.dynamicFee[tradingPair]))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setDynamicSpread(parseFloat(response.dynamicFee[tradingPair]));
                }
                else {
                    setDynamicSpread(parseFloat(response.fee))
                }
                setSpreadLoading(false);
            })
            .catch(error => {
                setSpreadError(error.message);
                setSpreadLoading(false);
            });
    }, [clientName, update, tradingPair]);

    useEffect(() => {
        getClientCommissionThreshold(clientId, tradingPair)
        
    },[clientId, update, tradingPair])
    useEffect(() => {
        if(commissionStructure.length> 0) {
            // find the treshold for current dynamic fee
            let dynamicObj = commissionStructure.filter(comm => comm.rate == dynamicSpread)[0]
            if(dynamicObj){
                console.log('dynamicFee', dynamicSpread);
                console.log('oldFee', oldFee);
                if(dynamicSpread !== oldFee){
                    // fee changed due to structure changed -> send out notification
                    clientService.sendCommissionChangeNoty(client.copperId,dynamicObj.threshold, dynamicSpread, dynamicObj.duration + " " + dynamicObj.unit, client.label, oldFee, tradingPair)
                    .then(result => console.log(result))

                }
            }
            
        }
        
    }, [oldFee, dynamicSpread, update])
    useEffect(() => {
        if (!clientName) return; // Don't fetch if no client name selected
        setSpreadLoading(true);
        setSpreadError(null);
        clientService.getSpread(client.copperId)
            .then(response => {
                console.log(response)
                // get the fee based on trading pair selected
                if(!isNaN(parseFloat(response.dynamicFee[tradingPair]))) {
                    // setSpread(response.fee.replace(/[^0-9.]/g, ''));
                    setOldFee(parseFloat(response.dynamicFee[tradingPair]));
                }
                else {
                    setOldFee(parseFloat(response.fee))
                }
            })
            .catch(error => {
                console.log(error)
            });
    },[clientId, tradingPair])
    /**
     * Retrieves the client commission threshold for a specific trading pair.
     */
    const getClientCommissionThreshold = async(clientId, tradingPair) => {
        let res = await clientService.getClientCommissionThresholdByClientId(clientId, tradingPair)
        console.log(res)
        // let duration
        let formatted = res
        .sort((a, b) => parseFloat(a.threshold) - parseFloat(b.threshold))
        .map(comm => {
            let unit = comm.duration.split(' ')[1]
            let tradeDuration = comm.duration.split(' ')[0]
            return {
                clientId: comm.client_id,
                id: comm.id,
                threshold: comm.threshold,
                rate: comm.fee,
                duration: tradeDuration,
                unit: unit,
            }
        })
        if(formatted[0]){
            console.log(formatted[0]);
            let duration = formatted[0].duration + ' ' + formatted[0].unit
            console.log('duration', duration);
            await getClienTradingVolume(clientId, tradingPair, duration)
        }
        else {
            await getClienTradingVolume(clientId, tradingPair, '0')
        }
        setCommissionStructure(formatted)
    }

    /**
     * Retrieves the trading volume for a specific client and asset pair within a given duration.
     */
    const getClienTradingVolume = async (clientId, assetPair, duration) => {
        console.log('hello');
        let res = await clientService.getClienTradingVolume(clientId, assetPair, duration)
        console.log(res);
        if(res) {
            setTradingVol(res)
        }
        else {
            setTradingVol(0)
        }
    }
    /**
     * Handles the addition of a new commission structure to the existing commission structure array.
     * Creates a new commission structure object with default values and adds it to the array.
     * If the existing commission structure array is not empty, the new commission structure will
     * inherit the duration and unit values from the first commission structure in the array.
     */
    const handleAddCommissionStructure = () => {
        let commStructure = [...commissionStructure]
        let newComm = {
            threshold: '',
            rate: '',
            duration: '1',
            unit: 'months',
            
        }
        if(commStructure.length > 0){
            newComm.duration = commStructure[0].duration
            newComm.unit = commStructure[0].unit
        }
        
        commStructure.push(newComm)
        setCommissionStructure(commStructure)
    }
    /**
     * Removes a commission structure from the commissionStructure array at the specified index.
     */
    const removeCommissionStructure = (index) => {
        let commStructure = [...commissionStructure]
        commStructure.splice(index, 1);
        setCommissionStructure(commStructure)
    }
    /**
     * Updates the commission structure based on the given parameters.
     */
    const updateCommissionStructure = (index, type, value) => {
        let commStructure = [...commissionStructure]
        console.log('commStructure', commStructure);
        if(type == 'threshold'){
            // value needs to be larger than the previous row (value > commStructure[index-1].threshold)
            if(commStructure[index-1] && Number(commStructure[index-1].threshold) >= Number(value)){
                console.log(commStructure[index-1].threshold);
                console.log(commStructure[index-1].threshold >= value); 
                console.log('value larger than last index', value);
                setValueInputError(index)
            }
            else {
                console.log('value less than last index', value);
                commStructure[index].threshold = value
                setValueInputError(-1)
            }
        }
        else if(type == 'rate') {
            // console.log(prevRateValue);
            // console.log(commissionStructure);
            if(parseFloat(value) > parseFloat(spread)){
                console.log('commission rate higher than client spread', value);
                setValueInputError(index)
            }
            else {
                commStructure[index].rate = value
                setValueInputError(-1)
            }
            // if(commissionStructure[index-1]){
            //     if(parseFloat(value) > parseFloat(commissionStructure[index-1].rate)){
            //         console.log('commission rate higher than client spread', value);
            //         setValueInputError(index)
            //     }
            //     else {
            //         console.log('set value');
            //         commStructure[index].rate = value
            //         setValueInputError(-1)
            //     }
            // }
            // else {
            //     console.log('set value');
            //     commStructure[index].rate = value
            //     setValueInputError(-1)
            // }
        }
        else if(type == 'duration'){
            //commStructure[index].duration = value
            commStructure.map(comm => comm.duration = value)
        }
        else if(type == 'unit'){
            commStructure.map(comm => comm.unit = value)
            //commStructure[index].unit = value
        }
        setCommissionStructure(commStructure)
        
    }

    /**
     * Submits a new client commission threshold to the server.
     */
    const submitNewClientCommissionThreshold = async(index) => {
        console.log(commissionStructure[index])
        let counter = update + 1
        let {threshold, rate, duration, unit} = commissionStructure[index]
        let clientPrevFee = dynamicSpread? dynamicSpread : spread
        console.log('SENDING NEW: ' + tradingPair);
        let result = await clientService.createNewClientCommissionThreshold(clientId, threshold, rate, duration + ' ' + unit, clientName, clientPrevFee, tradingPair)
        // // console.log('result', result);
        if(result.id){
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Creating client commission threshold successfully!")
            setUpdate(counter)
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Creating client commission threshold failed!")
            return false
        }
    }
    /**
     * Updates the commission threshold for a client.
     */
    const updateClientCommissionThreshold = async(index) => {
        console.log(commissionStructure[index])
        let counter = update + 1
        let formattedData = {
            id: commissionStructure[index].id,
            client_id: commissionStructure[index].clientId,
            threshold: commissionStructure[index].threshold,
            fee: commissionStructure[index].rate,
            duration: commissionStructure[index].duration + " " + commissionStructure[index].unit,
            assetpair: tradingPair
        }
        console.log('updateClientCommissionThreshold',formattedData);
        let clientPrevFee = dynamicSpread? dynamicSpread : spread
        let res = await clientService.updateClientCommissionThresholdById(formattedData, clientName, clientPrevFee )
        console.log(res)
        if(res[0] === 1){
            setIsError(false)
            setIsSuccess(true)
            setUpdate(counter)
            setSuccessMsg("Updating client commission threshold successfully!")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Updating client commission threshold failed!")
            return false
        }
    }
    /**
     * Handles the saving of all commission structures by iterating through each structure
     * and updating the corresponding data in the database.
     */
    const handleSaveAllCommissionStructure = async() => {
        // console.log(commissionStructure)
        setLoading(true)
        let counter = update + 1
        let successRes;
        let failedIndex;
        for(let i = 0; i < commissionStructure.length; i ++) {
            console.log(commissionStructure[i])
            if(commissionStructure[i].threshold == '' || commissionStructure[i].rate == ''){
                successRes = 0
                failedIndex = i + 1
                break
            }
            else {
                let formattedData = {
                    id: commissionStructure[i].id,
                    client_id: commissionStructure[i].clientId ?? clientId,
                    threshold: commissionStructure[i].threshold,
                    fee: commissionStructure[i].rate,
                    duration: commissionStructure[i].duration + " " + commissionStructure[i].unit,
                    assetpair: tradingPair
                }
                let clientPrevFee = dynamicSpread? dynamicSpread : spread
                let res = await clientService.updateClientCommissionThresholdById(formattedData, clientName, clientPrevFee )
                successRes = res[0] ? res[0] : res.id
                if(!successRes) {
                    failedIndex = i + 1
                    break
                }
            }
            
        }
        if(successRes) {
            setIsError(false)
            setIsSuccess(true)
            setUpdate(counter)
            setSuccessMsg("Updating client commission threshold successfully!")
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg(`Updating client commission threshold (row ${failedIndex}) failed!`)
        }
        setLoading(false)
    }
    /**
     * Deletes a commission structure from the database based on the given ID.
     */
    const deleteCommissionStructureFromDB = async(id) => {
        let counter = update + 1
        let res = await clientService.deleteClientCommissionThresholdById(id)
        console.log(res)
        if(res === 1) {
            setIsError(false)
            setIsSuccess(true)
            setUpdate(counter)
            setSuccessMsg("Deleting client commission threshold successfully! The rate would be updated on the next incoming trades")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Deleting client commission threshold failed!")
            return false
        }
    }
    useEffect(() => {
        console.log(valueInputError);
    },[valueInputError])
    return (
        <div>
            <Paper sx={{ minWidth: '60%', maxWidth: '80%', margin: 'auto', padding: 5, m: 'auto' }}>
                <Stack spacing={1}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Client</div>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={clientOptions}
                                sx={{width: '100%'}}
                                size='small'
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{textAlign: 'left' }} {...props}>
                                        {option.label}
                                    </Box>
                                )}
                                // value={clientId}
                                // getOptionLabel={(option) => option.label}
                                onChange={(e, v) => {
                                    console.log('v', v)
                                    setClient(v)
                                    setClientName(v.label)
                                    setClientId(v.value)
                                }}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Client Name"/>}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Default spread</div>
                        <FormControl sx={{ m: 1, width: 100 }}>
                            <TextField 
                                value={spreadLoading ? 'Loading...' : spread} 
                                readOnly={spreadLoading} 
                                onChange={(e) => setSpread(e.target.value)} 
                                error={spreadError}
                                helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                required
                                size='small'
                                disabled={true}
                                InputProps={{
                                    endAdornment: '%'
                                }}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Current Trading Vol.</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spreadLoading ? 'Loading...' : tradingVol} 
                                readOnly={spreadLoading} 
                                // onChange={(e) => setSpread(e.target.value)} 
                                // error={spreadError}
                                helperText={spreadError ? 'Failed to load: ' + {spreadError} : null}
                                required
                                size='small'
                                disabled={true}
                                InputProps={{
                                    startAdornment: '$'
                                }}
                            />
                        </FormControl>
                        {dynamicSpread ?
                        <>
                            <div style={{display: 'flex', alignItems: 'center'}}>Dynamic rate</div>
                            <FormControl sx={{ m: 1, width: 100 }}>
                                <TextField 
                                    value={spreadLoading ? 'Loading...' : dynamicSpread} 
                                    readOnly={spreadLoading} 
                                    onChange={(e) => setDynamicSpread(e.target.value)} 
                                    error={spreadError}
                                    helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                    required
                                    size='small'
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: '%'
                                    }}
                                />
                            </FormControl>
                        </>: null}
                        <div style={{display: 'flex', alignItems: 'center'}}>Asset Pair</div>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={tradingPairOptions}
                                sx={{width: '100%'}}
                                size='small'
                                value={tradingPair}
                                freeSolo
                                filterOptions={(options, state) => {
                                    // custom filter function; checks if the *beginning*
                                    // of each option (Trading Pair) matches the user input:
                                    const inputValue = state.inputValue.toLowerCase();
                                    const inputLength = inputValue.length;
                                    return options.filter((option) => {
                                        // slicing the optionLabel up to the length of the inputValue
                                        const optionLabel = option.label.toLowerCase().slice(0, inputLength);
                                        // and comparing the two strings.
                                        return optionLabel === inputValue;
                                    });
                                }}
                                onInputChange={(e, v) => {
                                    // console.log('input', e);
                                    // console.log('input', v);
                                    setTradingPair(v.toUpperCase())}}
                                // onChange={(e, v) => {
                                //     console.log(v)
                                //     setTradingPair(v ? v.value : '')
                                // }} // example of v: {value: 'BTC/CAD', label: 'BTC/CAD'}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Assets Pair"/>}
                            />
                        </FormControl>
                    </Stack>
                    <Stack>
                        {commissionStructure.map((commStruct, index) => (
                            <Stack direction={'row'} key={index}>
                                <FormControl sx={{ m: 1, minWidth: 250 }}>
                                    <TextField 
                                        value={commissionStructure[index].threshold}
                                        error={valueInputError === index}
                                        helperText={valueInputError === index ? "Please make sure the threshold is higher than the previous one." : null} 
                                        onChange={(e) => updateCommissionStructure(index, 'threshold', e.target.value)} 
                                        required
                                        size='small'
                                        // disabled={true}
                                        placeholder="Trading volume threshold"
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                    <TextField
                                        error={valueInputError === index}
                                        helperText={valueInputError === index ? "Please make sure the rate doesn't exceed the previous rate" : null}
                                        value={commissionStructure[index].rate} 
                                        onChange={(e) => updateCommissionStructure(index, 'rate', e.target.value)} 
                                        required
                                        size='small'
                                        // disabled={true}
                                        placeholder="Commission Rate"
                                        InputProps={{
                                            endAdornment: '%',
                                            inputComponent: NumericCustom
                                        }}
                                        // InputProps={{
                                        //     endAdornment: '%'
                                        // }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 100 }}>
                                    <TextField
                                        placeholder="Trading Interval"
                                        value={commissionStructure[index].duration}
                                        onChange={(e) => updateCommissionStructure(index, 'duration', e.target.value)} 
                                        size='small'
                                        disabled={index !== 0}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <CustomizedSelect 
                                                    size='small' value={commissionStructure[index].unit} 
                                                    onChange={(e) => updateCommissionStructure(index, 'unit', e.target.value)} 
                                                    // classes={classes.select}
                                                >
                                                    <MenuItem value="days">Days</MenuItem>
                                                    <MenuItem value="weeks">Weeks</MenuItem>
                                                    <MenuItem value="months">Months</MenuItem>
                                                    <MenuItem value="years">Years</MenuItem>
                                                </CustomizedSelect>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                <IconButton aria-label="delete" color='error'
                                    onClick={() => commissionStructure[index].id ? deleteCommissionStructureFromDB(commissionStructure[index].id):removeCommissionStructure(index)}
                                    //disabled={!commissionStructure[index].threshold || !commissionStructure[index].rate || !commissionStructure[index].duration} 
                                ><CloseIcon /></IconButton>
                                <IconButton aria-label="submit" color='success' 
                                    disabled={ valueInputError == index ||!commissionStructure[index].threshold || !commissionStructure[index].rate || !commissionStructure[index].duration} 
                                    onClick={() => commissionStructure[index].id ? updateClientCommissionThreshold(index):submitNewClientCommissionThreshold(index)}><CheckIcon /></IconButton>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack spacing={1}>
                        <Button variant="outlined" onClick={handleAddCommissionStructure} disabled={!clientName || !clientId}>Add commissions for trading volume</Button>
                        <LoadingButton loading={loading} loadingPosition="start" variant="contained" onClick={handleSaveAllCommissionStructure} disabled={valueInputError > 0 || !clientName || !clientId}>Save All</LoadingButton>
                    </Stack>

                    
                </Stack>
            </Paper>
            {tradingPair == 'ALL' && otherCommissionStructures.length > 0 ? <div style={{ margin: 10, fontSize:"large", fontWeight: 'bold'}}>Commission Structures for Other Asset Pairs</div> : null}
            {tradingPair == 'ALL' && otherCommissionStructures.map(comms => <Paper sx={{ minWidth: '60%', maxWidth: '80%', padding: 5, m: 'auto', mb: 3 }}>
                <Stack>
                    <Button style={{margin: 5, alignItems: 'flex-start'}} onClick={() => {setTradingPair(comms[0].assetpair)}}>{comms[0].assetpair}</Button>
                        {comms.map((commStruct, index) => (
                            <Stack direction={'row'} key={index}>
                                <FormControl sx={{ m: 1, minWidth: 250 }}>
                                    <TextField 
                                        value={comms[index].threshold}
                                        error={valueInputError === index}
                                        helperText={valueInputError === index ? "Please make sure the threshold is higher than the previous one." : null} 
                                        onChange={(e) => updateCommissionStructure(index, 'threshold', e.target.value)} 
                                        required
                                        size='small'
                                        disabled
                                        placeholder="Trading volume threshold"
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                    <TextField
                                        error={valueInputError === index}
                                        helperText={valueInputError === index ? "Please make sure the rate doesn't exceed the previous rate" : null}
                                        value={comms[index].fee} 
                                        onChange={(e) => updateCommissionStructure(index, 'rate', e.target.value)} 
                                        required
                                        size='small'
                                        disabled
                                        // disabled={true}
                                        placeholder="Commission Rate"
                                        InputProps={{
                                            endAdornment: '%',
                                            inputComponent: NumericCustom
                                        }}
                                        // InputProps={{
                                        //     endAdornment: '%'
                                        // }}
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 100 }}>
                                    <TextField
                                        placeholder="Trading Interval"
                                        value={comms[index].duration}
                                        onChange={(e) => updateCommissionStructure(index, 'duration', e.target.value)} 
                                        size='small'
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <CustomizedSelect 
                                                    size='small' value={comms[index].unit} 
                                                    onChange={(e) => updateCommissionStructure(index, 'unit', e.target.value)} 
                                                    // classes={classes.select}
                                                    disabled
                                                >
                                                    <MenuItem value="days">Days</MenuItem>
                                                    <MenuItem value="weeks">Weeks</MenuItem>
                                                    <MenuItem value="months">Months</MenuItem>
                                                    <MenuItem value="years">Years</MenuItem>
                                                </CustomizedSelect>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                {/* <IconButton aria-label="delete" color='error'
                                    onClick={() => commissionStructure[index].id ? deleteCommissionStructureFromDB(commissionStructure[index].id):removeCommissionStructure(index)}
                                    //disabled={!commissionStructure[index].threshold || !commissionStructure[index].rate || !commissionStructure[index].duration} 
                                ><CloseIcon /></IconButton>
                                <IconButton aria-label="submit" color='success' 
                                    disabled={ valueInputError == index ||!commissionStructure[index].threshold || !commissionStructure[index].rate || !commissionStructure[index].duration} 
                                    onClick={() => commissionStructure[index].id ? updateClientCommissionThreshold(index):submitNewClientCommissionThreshold(index)}><CheckIcon /></IconButton> */}
                            </Stack>
                        ))}
                    </Stack>
            </Paper>)}
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
                <Alert severity="error" onClose={() => setIsError(false)}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
                <Alert severity="success" onClose={() => setIsSuccess(false)}>
                    {successMsg}
                </Alert>
            </Snackbar>
                        {/* <div style={{display: 'flex', alignItems: 'center'}}>is asking in terms of</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={askingTermsSelect}
                                onChange={(e) => setAskingTermsSelect(e.target.value)} 
                                required
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                {askingTermsOptions.map((option) => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trading Pair</div>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={tradingPairOptions}
                                sx={{width: '100%'}}
                                size='small'
                                // value={clientId}
                                freeSolo
                                onInputChange={(e, v) => {
                                    // console.log('input', e);
                                    console.log('input', v);
                                    setTradingPair(v.toUpperCase())}}
                                // onChange={(e, v) => {
                                //     console.log(v)
                                //     setTradingPair(v ? v.value : '')
                                // }} // example of v: {value: 'BTC/CAD', label: 'BTC/CAD'}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Assets Pair"/>}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={amount} 
                                onChange={(e) => {
                                    // console.log('e', e)
                                    setAmount(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[1]:tradingPair.split('/')[0]
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Spread/Fee (%)</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spreadLoading ? 'Loading...' : spread} 
                                readOnly={spreadLoading} 
                                onChange={(e) => setSpread(e.target.value)} 
                                error={spreadError}
                                helperText={spreadError ? 'Failed to load spread: ' + {spreadError} : null}
                                required
                                size='small'
                                disabled={!enableFeeChange}
                            />
                        </FormControl>
                        <Button onClick={() => handleEnableFeeChangeDialog()}>Change Fee</Button>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Spot Rate</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={spotRate} 
                                onChange={(e) => {
                                    setSpotRate(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Client Dealt Rate</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={clientDealtRate}
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount to Trade</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={Number(amountToTrade).toFixed(decimalPlace)} 
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: askingTermsSelect === 'Fiat'? tradingPair.split('/')[0]:tradingPair.split('/')[1]
                                }}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trade ID</div>
                        <FormControl sx={{ m: 1, minWidth: 240 }}>
                            <TextField 
                                value={tradeTicketID}
                                disabled
                                required
                                size='small'
                                sx={{width: '100%'}}
                                // fullWidth
                            />
                        </FormControl>
                    </Stack>
                    <Stack sx={{ m: 2}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                </Stack>
            </Paper>
            {showTable ? 
                <Stack style={{marginTop: '5px'}}>
                    <TradeTicketsTable
                        accessToken={token}
                        tradesData={rawTrade}
                        loading={loading}
                        curUser={curUser}
                        update={update}
                        setUpdate={setUpdate}
                        setErrorMsg={setErrorMsg}
                        setIsError={setIsError}
                        setSuccessMsg={setSuccessMsg}
                        setIsSuccess={setIsSuccess}
                    />
                </Stack>
                :null}
            
            <TradePreviewDialog 
                open={showPreview} 
                submitTicket={(e) => handleSubmit(e)}
                handleClose={() => setShowPreview(false)}
                clientName={clientName}
                tradeTicketID={tradeTicketID}
                tradingPair={tradingPair}
                action={action}
                spread={spread}
                amount={amount}
                spotRate={spotRate}
                clientDealtRate={clientDealtRate}
                amountToTrade={amountToTrade}
                askingTermsSelect={askingTermsSelect}
                date={moment().format("MMM DD, YYYY")}
                decimalPlace={decimalPlace}
                dealtDecimal={dealtDecimals}
            />
            <Modal
                open={feeChangeAlert}
                onClose={() => setFeeChangeAlert(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                        Confirm to change fee
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    </Typography>
                    <Stack direction={'row'} spacing={1} sx={{justifyContent: 'center'}}>
                        <Button variant='contained' color='primary' onClick={() =>  handleConfirmEnableFeeChange()}>Confirm</Button>
                        <Button variant='contained' color='error' onClick={() =>  setFeeChangeAlert(false)}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal> */}
        </div>
    );
}

export default TradeCommission;