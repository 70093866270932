import React, { useEffect, useState } from 'react';
import { ClientService } from '../../../api/ClientService';
import { TradeTicketService } from '../../../api/TradeTicketService';
import { rates } from '../TradeTicketGenerator/rates';
import { Stack, Button, Select, MenuItem, FormControl, TextField, Autocomplete, Snackbar, Paper, Modal, Box, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import PreviewDialog from './PreviewDialog';
import { NumericFormat } from 'react-number-format';
import TradesTable from './TradesTable';
const TRADERS = ['Yacine Ouldchikh', 'Mostafa Al-Mashita', 'Ahmed Nasser']
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

/**
 * A custom input component for formatting numeric values.
 * @param {object} props - The component props.
 * @param {function} props.onChange - The callback function to handle value changes.
 * @param {React.Ref} ref - The ref object for accessing the input element.
 * @returns A React component that formats numeric values.
 */
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                    name: props.name,
                    value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

/**
 * A custom alert component that forwards the ref to the underlying MuiAlert component.
 * @param {object} props - The props passed to the component.
 * @param {React.Ref} ref - The ref to be forwarded to the underlying MuiAlert component.
 * @returns The rendered MuiAlert component with the provided props and ref.
 */
const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Trade Ticket Generator
function VoiceChatLPExecution({curUser, showTable, token}) {
    const [liquidityProvider, setLiquidityProvider] = useState('B2C2');
    const [action, setAction] = useState('Buy');
    const [amountTraded, setAmountTraded] = useState('0');
    const [price, setPrice] = useState('0');
    const [totalNotional, setTotalNotional] = useState('0');
    const [tradeTimestamp, setTradeTimestamp] = useState(dayjs());
    const [trader, setTrader] = useState('')
    const [tradingPair, setTradingPair] = useState('BTC/USD');
    const [decimalPlace, setDecimalPlace] = useState(2);
    // getting trades
    const [rawTrade, setRawTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(0);
    // preview dialog
    const [showPreview, setShowPreview] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [tradingPairOptions, setTradingPairOptions] = useState([]);   // options in the Trading Pairs select/pulldown

    /**
     * Creates a new instance of the ClientService and TradeTicketService classes using the provided token.
     * @param {string} token - The authentication token used to access the client and trade services.
     * @returns {ClientService} - An instance of the ClientService class.
     * @returns {TradeTicketService} - An instance of the TradeTicketService class.
     */
    const clientService = new ClientService(token);
    const tradeService = new TradeTicketService(token);

    useState(() => {
        console.log(curUser);
        setTrader(curUser.name)
    },[curUser]);

    // State for loading and error state of Trading Pairs API call
    useEffect(() => {
        const fetchTradingPairs = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + '/tradingpairs',
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const tradingPairs = data.map(item => ({
                    value: item.trading_pair,
                    label: item.trading_pair,
                })).sort((a, b) => a.value < b.value ? -1 : a.value > b.value ? 1 : 0);
                setTradingPairOptions(tradingPairs);
            } catch (error) {
                console.error('There was an error fetching trading pairs!', error);
            }
        };
    
        fetchTradingPairs();
    }, []);
    
    // Load all Trade Tickets and store them with setRawTrades
    useEffect(() => {
        tradeService.getAllVoiceChatTradeTicket().then(rawTrade => {
            console.log(rawTrade);
            setRawTrades(rawTrade);
            setLoading(false)
        }).catch(err =>{ 
            console.log(err)
            setLoading(false)
        });
    }, [update]);

    /**
     * Submits a trade ticket and handles the response.
     * @param {any} res - The response from the trade service.
     * @returns {Promise<boolean>} - A promise that resolves to true if the trade ticket was created successfully, false otherwise.
     */
    async function submittedTradeTicket(res) {
        let result = await tradeService.createVoiceChatTradeTicket(res);
        // console.log('result', result);
        if(result.id){
            // trigger increment function 
            // let id_result = await tradeService.updateTradeId();
            // @TODO: handle error
            setIsError(false)
            setIsSuccess(true)
            setSuccessMsg("Creating trade successfully!")
            return true
        }
        else {
            setIsSuccess(false)
            setIsError(true)
            setErrorMsg("Creating trade failed!")
            return false
        }
    }

    /**
     * Handles the form submission event and performs the necessary actions.
     * @param {Event} event - The form submission event.
     * @returns None
     */
    const handleSubmit = async(event) => {
        console.log('handleSubmit running');
        event.preventDefault();
        let count = update + 1
        let result = await submittedTradeTicket({
            liquidity_provider: liquidityProvider,
            direction: action,
            trading_pair: tradingPair,
            amount_traded: amountTraded,
            price: price,
            total_notional: totalNotional,
            date: tradeTimestamp,
            trader: trader,
        });
        if(result){
            setShowPreview(false)
            setUpdate(count)
            setLiquidityProvider('')
            setTradingPair('BTC/USD')
            setAction('Buy')
            setAmountTraded('0')
            setPrice('0')
            setTotalNotional('0')
            setTradeTimestamp(dayjs())
        }
    };
    /**
     * A React useEffect hook that updates the decimal place state based on the selected trading pair and rates.
     */
    useEffect(() => {
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        // if(action == 'Buy'){
        //     if(tradingPair1){
        //         if(rates[tradingPair1]){
        //             let rate = rates[tradingPair1].decimal
        //             setDecimalPlace(rate)
        //         }
        //     }
        // }
        // else {
        //     if(tradingPair2){
        //         if(rates[tradingPair2]){
        //             let rate = rates[tradingPair2].decimal
        //             setDecimalPlace(rate)
        //         }
        //     }
        // }
        if(tradingPair2){
            if(rates[tradingPair2]){
                let rate = rates[tradingPair2].decimal
                setDecimalPlace(rate)
            }
        }
    },[tradingPair, action])

    const handleDateTimeChange = (date) => {
        // Handle the change event here
        console.log(date);
        setTradeTimestamp(date)
    };

    /**
     * A side effect hook that calculates the total notional value based on the amount traded and price.
     * It updates the total notional value whenever any of the dependencies (amountTraded, price, tradingPair, decimalPlace) change.
     */
    useEffect(() => {
        let total = amountTraded * price
        setTotalNotional(total.toFixed(decimalPlace))
    },[amountTraded, price, tradingPair, decimalPlace])

    return (
        <Paper style={{padding: 5}}>
            {!showTable ? <Typography variant="button" gutterBottom>Voice Chat LP</Typography> : null}
            <div>
                <Stack spacing={1}>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Liquidity Provider</div>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={liquidityProvider}
                                onChange={(e) => setLiquidityProvider(e.target.value)} required
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                <MenuItem value={'aquanow'}>Aquanow</MenuItem>
                                <MenuItem value={'b2c2'}>B2C2</MenuItem>
                                <MenuItem value={'cumberland'}>Cumberland</MenuItem>
                                <MenuItem value={'dvchain'}>DV Chain</MenuItem>
                                <MenuItem value={'enigma'}>Enigma</MenuItem>
                                <MenuItem value={'flowtraders'}>Flow Traders</MenuItem>
                                <MenuItem value={'galaxy'}>Galaxy</MenuItem>
                                <MenuItem value={'mobilum'}>Mobilum</MenuItem>
                                <MenuItem value={'nonco'}>Nonco</MenuItem>
                                <MenuItem value={'wintermute'}>Wintermute</MenuItem>
                                <MenuItem value={'falconx'}>FalconX</MenuItem>
                                <MenuItem value={'stillmandigital'}>Stillman Digital</MenuItem>
                                <MenuItem value={'crypto.com'}>Crypto.com</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Direction</div>
                        <FormControl sx={{ minWidth: 120 }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={action}
                                onChange={(e) => setAction(e.target.value)} required
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{widht: '100%'}}
                                size='small'
                            >
                                <MenuItem value={'Buy'}>Buy</MenuItem>
                                <MenuItem value={'Sell'}>Sell</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trading Pair</div>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={tradingPairOptions}
                                sx={{width: '100%'}}
                                size='small'
                                freeSolo
                                filterOptions={(options, state) => {
                                    // custom filter function; checks if the *beginning*
                                    // of each option (Trading Pair) matches the user input:
                                    const inputValue = state.inputValue.toLowerCase();
                                    const inputLength = inputValue.length;
                                    return options.filter((option) => {
                                        // slicing the optionLabel up to the length of the inputValue
                                        const optionLabel = option.label.toLowerCase().slice(0, inputLength);
                                        // and comparing the two strings.
                                        return optionLabel === inputValue;
                                    });
                                }}
                                onInputChange={(e, v) => {
                                    // console.log('input', e);
                                    // console.log('input', v);
                                    setTradingPair(v.toUpperCase())}}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Assets Pair"/>}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Amount Traded</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={amountTraded} 
                                onChange={(e) => {
                                    // console.log('e', e)
                                    setAmountTraded(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: tradingPair && tradingPair.includes('/') ? tradingPair.split('/')[0] : ''
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Price</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={price} 
                                onChange={(e) => {
                                    setPrice(e.target.value)
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                }}
                                required
                                size='small'
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Total Notional</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <TextField 
                                value={totalNotional}
                                disabled
                                required
                                size='small'
                                InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    endAdornment: tradingPair && tradingPair.includes('/') ? tradingPair.split('/')[1] : ''
                                }}
                            />
                        </FormControl>
                        <div style={{display: 'flex', alignItems: 'center'}}>Date</div>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker 
                                    // label="Basic date time picker" 
                                    slotProps={{ textField: { size: 'small', hiddenLabel: true } }}
                                    onChange={handleDateTimeChange}
                                    value={tradeTimestamp}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={1}>
                        <div style={{display: 'flex', alignItems: 'center'}}>Trader</div>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={TRADERS}
                                sx={{width: '100%'}}
                                size='small'
                                freeSolo
                                onInputChange={(e, v) => {
                                    // console.log('input', e);
                                    // console.log('input', v);
                                    setTrader(v)}
                                }
                                value={trader}
                                renderInput={(params) => 
                                <TextField {...params} placeholder="Trader"/>}
                            />
                        </FormControl>
                    </Stack>
                    <Stack sx={{ m: 2}}>
                        <Button variant="outlined" onClick={() => setShowPreview(true)}>Preview</Button>
                    </Stack>
                </Stack>
            </div>
            {/* {showTable ?  */}
                <Stack style={{marginTop: '5px'}}>
                    <TradesTable
                        accessToken={token}
                        tradesData={rawTrade}
                        loading={loading}
                        curUser={curUser}
                        update={update}
                        setUpdate={setUpdate}
                        setErrorMsg={setErrorMsg}
                        setIsError={setIsError}
                        setSuccessMsg={setSuccessMsg}
                        setIsSuccess={setIsSuccess}
                    />
                </Stack>
                {/* :null} */}
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
                <Alert severity="error" onClose={() => setIsError(false)}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={6000} onClose={() => setIsSuccess(false)}>
                <Alert severity="success" onClose={() => setIsSuccess(false)}>
                    {successMsg}
                </Alert>
            </Snackbar>
            <PreviewDialog  
                open={showPreview} 
                submitTicket={(e) => handleSubmit(e)}
                handleClose={() => setShowPreview(false)}
                tradingPair={tradingPair}
                date={dayjs(tradeTimestamp).format('lll')}
                decimalPlace={decimalPlace}
                liquidityProvider={liquidityProvider}
                amountTraded={amountTraded}
                price={price}
                totalNotional={totalNotional}
                action={action}
            />
            
        </Paper>
    );
}

export default VoiceChatLPExecution;