export const rates = {
    'BTC': {
        decimal: 8,
        'BTC/USD': 2,
        'BTC/CAD': 2,
        'BTC/EUR': 2,
        'BTC/GBP': 2,
        'BTC/USDT': 2,
        'BTC/USDC': 2,
        'BTC/ETH': 4,
    },
    'ETH': {
        decimal: 6,
        'ETH/USD': 2,
        'ETH/GBP': 2,
        'ETH/EUR': 2,
        'ETH/CAD': 2,
        'ETH/USDT': 2,
        'ETH/USDC': 2,
        'ETH/BTC': 5,
    },
    'USDT': {
        decimal: 5,
        'USDT/USD': 5,
        'USDT/CAD': 5,
        'USDT/EUR': 5,
        'USDT/GBP': 5,
        'USDT/USDC': 5,
    },
    'USDC': {
        decimal: 5,
        'USDC/USD': 5,
        'USDC/GBP': 5,
        'USDC/EUR': 5,
        'USDC/CAD': 5,

    },
    'AVAX': {
        decimal: 2,
        'AVAX/USD':2,
        'AVAX/GBP':2,
        'AVAX/EUR':2,
        'AVAX/CAD':2,
        'AVAX/USDT':2,
        'AVAX/USDC':2,
        'AVAX/ETH':2,
    },
    'BNB': {
        decimal: 2,
        'BNB/USD':2,
        'BNB/GBP':2,
        'BNB/EUR':2,
        'BNB/CAD':2,
        'BNB/USDT':2,
        'BNB/USDC':2,
        'BNB/ETH':2,
    },
    'ADA': {
        decimal: 4,
        'ADA/USD':4,
        'ADA/GBP':4,
        'ADA/EUR':4,
        'ADA/CAD':4,
        'ADA/USDT':4,
        'ADA/USDC':4,
        'ADA/ETH':4,
    },
    'LINK': {
        decimal: 3,
        'LINK/USD':3,
        'LINK/GBP':3,
        'LINK/EUR':3,
        'LINK/CAD':3,
        'LINK/USDT':3,
        'LINK/USDC':3,
        'LINK/ETH':4,
    },
    'DOGE': {
        decimal: 5,
        'DOGE/USD':5,
        'DOGE/GBP':5,
        'DOGE/EUR':5,
        'DOGE/CAD':5,
        'DOGE/USDT':5,
        'DOGE/USDC':5,
        'DOGE/ETH':4,
    },
    'LTC': {
        decimal: 3,
        'LTC/USD':3,
        'LTC/GBP':3,
        'LTC/EUR':3,
        'LTC/CAD':3,
        'LTC/USDT':3,
        'LTC/USDC':3,
        'LTC/ETH': 4
    },
    'PAX': {
        decimal: 4,
        'PAX/USD':4,
        'PAX/GBP':4,
        'PAX/EUR':4,
        'PAX/CAD':4,
        'PAX/USDT':4,
        'PAX/USDC':4,
        'PAX/ETH':4,
    },
    'DOT': {
        decimal: 4,
        'DOT/USD':4,
        'DOT/GBP':4,
        'DOT/EUR':4,
        'DOT/CAD':4,
        'DOT/USDT':4,
        'DOT/USDC':4,
        'DOT/ETH':4,
        'DOT/BTC': 5
    },
    'SHIB': {
        decimal: 8,
        'SHIB/USD':8,
        'SHIB/GBP':8,
        'SHIB/EUR':8,
        'SHIB/CAD':8,
        'SHIB/USDT':8,
        'SHIB/USDC':8,
        'SHIB/ETH':8,
        'SHIB/BTC':8,
    },
    'MATIC': {
        decimal: 4,
        'MATIC/USD':4,
        'MATIC/GBP':4,
        'MATIC/EUR':4,
        'MATIC/CAD':4,
        'MATIC/USDT':4,
        'MATIC/USDC':4,
        'MATIC/ETH':4,
        'MATIC/BTC':5
    },
    'SOL': {
        decimal: 3,
        'SOL/USD':3,
        'SOL/GBP':3,
        'SOL/EUR':3,
        'SOL/CAD':3,
        'SOL/USDT':3,
        'SOL/USDC':3,
        'SOL/ETH':4,
        'SOL/BTC':5
    },
    'ATOM': {
        decimal: 3,
        'ATOM/USD':3,
        'ATOM/GBP':3,
        'ATOM/EUR':3,
        'ATOM/CAD':3,
        'ATOM/USDT':3,
        'ATOM/USDC':3,
        'ATOM/BTC':5,
        'ATOM/ETH':4,
    },
    'XRP': {
        decimal: 4,
        'XRP/USD': 4,
        'XRP/GBP': 4,
        'XRP/EUR': 4,
        'XRP/CAD': 4,
        'XRP/USDT': 4,
        'XRP/USDC': 4,
        'XRP/BTC':5,
        'XRP/ETH':4,
    },
    'XTZ': {
        decimal: 4,
        'XTZ/USD': 4,
        'XTZ/GBP': 4,
        'XTZ/EUR': 4,
        'XTZ/CAD': 4,
        'XTZ/USDT': 4,
        'XTZ/USDC': 4,
        'XTZ/BTC':5,
        'XTZ/ETH':4,
    },
    'SUI': {
        decimal: 4,
        'SUI/USD': 4,
        'SUI/GBP': 4,
        'SUI/EUR': 4,
        'SUI/CAD': 4,
        'SUI/USDT': 4,
        'SUI/USDC': 4,
        'SUI/BTC':5,
        'SUI/ETH':4,
    },
    'SEI': {
        decimal: 4,
        'SEI/USD': 4,
        'SEI/GBP': 4,
        'SEI/EUR': 4,
        'SEI/CAD': 4,
        'SEI/USDT': 4,
        'SEI/USDC': 4,
        'SEI/BTC':5,
        'SEI/ETH':4,
    },
    'STX': {
        decimal: 4,
        'STX/USD': 4,
        'STX/GBP': 4,
        'STX/EUR': 4,
        'STX/CAD': 4,
        'STX/USDT': 4,
        'STX/USDC': 4,
        'STX/BTC':5,
        'STX/ETH':4,
    },
    'ORDI': {
        decimal: 4,
        'ORDI/USD': 4,
        'ORDI/GBP': 4,
        'ORDI/EUR': 4,
        'ORDI/CAD': 4,
        'ORDI/USDT': 4,
        'ORDI/USDC': 4,
        'ORDI/BTC':5,
        'ORDI/ETH':4,
    },
    'OP': {
        decimal: 4,
        'OP/USD': 4,
        'OP/GBP': 4,
        'OP/EUR': 4,
        'OP/CAD': 4,
        'OP/USDT': 4,
        'OP/USDC': 4,
        'OP/BTC':5,
        'OP/ETH':4,
    },
    'ARB': {
        decimal: 4,
        'ARB/USD': 4,
        'ARB/GBP': 4,
        'ARB/EUR': 4,
        'ARB/CAD': 4,
        'ARB/USDT': 4,
        'ARB/USDC': 4,
        'ARB/BTC':5,
        'ARB/ETH':4,
    },
    'APT': {
        decimal: 4,
        'APT/USD': 4,
        'APT/GBP': 4,
        'APT/EUR': 4,
        'APT/CAD': 4,
        'APT/USDT': 4,
        'APT/USDC': 4,
        'APT/BTC':5,
        'APT/ETH':4,
    },
    'CRV': {
        decimal: 4,
        'CRV/USD': 4,
        'CRV/GBP': 4,
        'CRV/EUR': 4,
        'CRV/CAD': 4,
        'CRV/USDT': 4,
        'CRV/USDC': 4,
        'CRV/BTC':5,
        'CRV/ETH':4,
    },
    'FTM': {
        decimal: 4,
        'FTM/USD': 4,
        'FTM/GBP': 4,
        'FTM/EUR': 4,
        'FTM/CAD': 4,
        'FTM/USDT': 4,
        'FTM/USDC': 4,
        'FTM/BTC':5,
        'FTM/ETH':4,
    },
    'TAO': {
        decimal: 3,
        'TAO/USD': 3,
        'TAO/GBP': 3,
        'TAO/EUR': 3,
        'TAO/CAD': 3,
        'TAO/USDT': 3,
        'TAO/USDC': 3,
        'TAO/BTC':5,
        'TAO/ETH':4,
    },
    'AAVE': {
        decimal: 3,
        'AAVE/USD': 3,
        'AAVE/GBP': 3,
        'AAVE/EUR': 3,
        'AAVE/CAD': 3,
        'AAVE/USDT': 3,
        'AAVE/USDC': 3,
        'AAVE/BTC':5,
        'AAVE/ETH':4,
    },
    'UNI': {
        decimal: 3,
        'UNI/USD': 3,
        'UNI/GBP': 3,
        'UNI/EUR': 3,
        'UNI/CAD': 3,
        'UNI/USDT': 3,
        'UNI/USDC': 3,
        'UNI/BTC':5,
        'UNI/ETH':4,
    },
    'USD': {
        decimal: 2,
    },
    'CAD': {
        decimal: 2,
    },
    'EUR': {
        decimal: 2,
    },
    'GBP': {
        decimal: 2,
    }
}
