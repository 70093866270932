import React, { useState, useEffect } from "react";
import Tasks from "./Tasks";
// import CreateTask from './CreateTask';
import { ClientService } from "../../../api/ClientService";
import CircularProgress from "@mui/material/CircularProgress";
import usePagination from "../../../hooks/usePagination";
import { searchAllClients } from "../../../api/searchAllClients";

function ClientMapping({ accessToken }) {
  const [tasks, setTasks] = useState([]);
  // console.log('Home() tasks', tasks)
  const [numberOfClients, setNumberOfClients] = useState(0);
  const [isTaskEdited, setTaskEdited] = useState(false);
  const [isWalletAdded, setWalletAdded] = useState(false);
  const [deletedWallet, setDeletedWallet] = useState(null);
  const [isClientnameAdded, setClientnameAdded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(0);
  const [balanceUpdate, setBalanceUpdate] = useState(0);
  const [clientFilter, setClientFilter] = useState("ALL");
  const [balancesDownloading, setBalancesDownloading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showBalanceDatePicker, setShowBalanceDatePicker] = useState(false)
  const [balancesDate, setBalancesDate] = useState(new Date())
  const [noResultsFound, setNoResultsFound] = useState(false);


  const paginationProps = usePagination();

  /**
   * Creates a new instance of the ClientService class with the provided access token.
   * @param {string} accessToken - The access token used for authentication.
   * @returns A new instance of the ClientService class.
   */
  const clientService = new ClientService(accessToken);
  const clientsSearch = new searchAllClients(accessToken);

  useEffect(() => {
    // console.log(update + "!!!!!!!")
  }, [update]);

  useEffect(() => {
    paginationProps.setPageIndex(0);
  }, [clientFilter, searchString]);

  function delTask(clientId) {
    clientService.deleteClient(clientId).then((response) => {
      setNumberOfClients(numberOfClients - 1);
    });
  }

  /**
   * Fetches search data from the API based on the provided search string.
   * If the search string is not empty, it sends a GET request to the API with the search query.
   * If the response is successful, it sets the fetched data as the tasks state and updates the pagination props.
   * If the response is not successful, it throws an error.
   * If the search string is empty, it calls the getAllClientsData function to get all clients data and sets the search results state.
   * If there is an error during the process, it logs the error to the console.
   */
  const fetchSearchData = async () => {
    setLoading(true)

    try {
      let url;
      if (searchString.trim() !== "") {
        // Construct URL for filtered data with combinedclientdata route
        url = `${process.env.REACT_APP_API_URL}/combinedclientdata?size=${paginationProps.pageSize}&page=${paginationProps.pageIndex}&searchQuery=${encodeURIComponent(searchString)}&settled_filter=${clientFilter}`;
      } else {
        // Construct URL for all clients with combinedclientdata route
        url = `${process.env.REACT_APP_API_URL}/combinedclientdata?size=${paginationProps.pageSize}&page=${paginationProps.pageIndex}&settled_filter=${clientFilter}`;
      }
      
      // Make an HTTP request to your backend endpoint for filtering
      const response = await fetch(url, {
        method: "GET",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const data = await response.json();
      
      setTasks(data.data);
      paginationProps.setTotalItemCount(data.totalItems);
      setNoResultsFound(data.data.length === 0);
    } catch (error) {
      console.error("Error fetching data:", error);
      setNoResultsFound(false); // Reset in case of an error
    }
    finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (searchString.trim().length === 0) {
      fetchSearchData();
    }
  }, [searchString, paginationProps.pageSize, paginationProps.pageIndex]);
  

  useEffect(() => {
    fetchSearchData()
  }, [clientFilter])

  // function clientCreated() {
  //     setNumberOfClients(numberOfClients + 1)
  // }

  function taskEdited(res) {
    setTaskEdited(res);
  }

  function walletAdded(res) {
    setWalletAdded(res);
  }

  function clientnameAdded(res) {
    setClientnameAdded(res);
  }

  /**
   * Downloads the client balances as an Excel file.
   */
  function downloadBalances() {
    setBalancesDownloading(true);

    const sheetType = "xlsx";

    clientService.getClientBalanceListBlob(sheetType, showBalanceDatePicker ? balancesDate : new Date()).then((blob) => {
      try {
        const blobURL = window.URL.createObjectURL(blob);
        const tmpLink = window.document.createElement("a");

        tmpLink.href = blobURL;
        tmpLink.download = `report.${sheetType}`;
        tmpLink.click();

        window.URL.revokeObjectURL(blobURL);
      } catch (err) {
        console.error(err);
      }

      setBalancesDownloading(false);
    });
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      {/* <CreateTask clientCreated={clientCreated} accessToken={accessToken}></CreateTask> */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchSearchData();
        }}
      >
        <input
          type="text"
          placeholder="Search clients"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          style={{ margin: '1em', height: '20px', width: '200px', fontSize: '18px' }}
        />
        <button type="submit">Search</button>
      </form>
      {noResultsFound && <p>No results found.</p>}
      <Tasks
        tasks={tasks}
        deleteClient={delTask}
        taskEdited={taskEdited}
        walletAdded={walletAdded}
        clientnameAdded={clientnameAdded}
        update={update}
        setUpdate={setUpdate}
        accessToken={accessToken}
        setDeletedWallet={setDeletedWallet}
        balanceUpdate={balanceUpdate}
        setBalanceUpdate={setBalanceUpdate}
        paginationProps={paginationProps}
        clientFilter={clientFilter}
        setClientFilter={setClientFilter}
        balancesDownloading={balancesDownloading}
        downloadBalances={downloadBalances}
        showBalanceDatePicker={showBalanceDatePicker}
        setShowBalanceDatePicker={setShowBalanceDatePicker}
        balancesDate={balancesDate}
        setBalancesDate={setBalancesDate}
      />
    </div>
  );
}

export default ClientMapping;
