import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import copy from 'copy-to-clipboard';
import { Stack } from '@mui/material';
import { rates } from './rates';
import LoadingButton from '@mui/lab/LoadingButton';

const CancelTradeModal = ({
  open,
  handleClose,
  previousTradeData,
  askingTermsSelect,
  clientName,
  tradeTicketID,
  tradingPair,
  date,
  action,
  spread,
  amount,
  spotRate,
  clientDealtRate,
  amountToTrade,
  decimalPlace,
  dealtDecimal,
  loading,
  submitTicket,
  liquidityProvider, execAmt, execPrice, platform, orderId
}) => {
  const [decimal, setDecimal] = React.useState(2);
  const [decimalDealt, setDecimalDealt] = React.useState(4);
  const [isCopied, setIsCopied] = React.useState(false); 
  const [isIndicativeCopied, setIsIndicativeCopied] = React.useState(false); 
  React.useEffect(() => {
    const [tradingPair1, tradingPair2] = tradingPair.split('/');
    if(tradingPair1 && tradingPair2){
        if(rates[tradingPair1]){
            let rate = rates[tradingPair1] ? rates[tradingPair1].decimal : 8
            setDecimal(rate)
        }
        if(rates[tradingPair2]){
            let rate = rates[tradingPair2] ? rates[tradingPair2].decimal : 8
            setDecimalDealt(rate)
        }
    }
},[tradingPair])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    width: "60%",
    // height: "500px",
    maxWidth: "100%",
    maxHeight: "100%",
    p: 4,
  };

  const handleCopyIndicativeTicket = () => {
    setIsIndicativeCopied(false)
    setIsCopied(false)
    let ticket = `*Indicative Summary* \n`+
        `Client: ${clientName} \n` +
        `Date: ${date} \n` +
        `CCY Deposited: ${
            askingTermsSelect == 'Fiat'? 
                                action == 'Buy'? 
                                    tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                        parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                        :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                        parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                        : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                : action == 'Buy'? 
                                    tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                        parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                        :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                        parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                        : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]} \n` +
        `Spot Price: ${
            askingTermsSelect == 'Fiat'? 
                parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
        `Dealt Rate: ${
            askingTermsSelect == 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
        `CCY Purchased: ${
            askingTermsSelect == 'Fiat'? 
                                action == 'Buy'? 
                                    tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                : action == 'Buy'? 
                                    tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
        }`
    copy(ticket)
    setIsIndicativeCopied(true)
}
  const handleCopyTradeTicket = () => {
    setIsCopied(false)
    setIsIndicativeCopied(false)
    let ticket = `*Trade Summary* \n`+
        `Client: ${clientName} \n` +
        `Date: ${date} \n` +
        `CCY Deposited: ${
            askingTermsSelect == 'Fiat'? 
                                action == 'Buy'? 
                                    tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                        parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                        :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                        parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                        : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                : action == 'Buy'? 
                                    tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                        parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                        :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                        parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                        : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]} \n` +
        `Spot Price: ${
            askingTermsSelect == 'Fiat'? 
                parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
        `Dealt Rate: ${
            askingTermsSelect == 'Fiat'? 
                parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
        `CCY Purchased: ${
            askingTermsSelect == 'Fiat'? 
                                action == 'Buy'? 
                                    tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                : action == 'Buy'? 
                                    tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
        } \n` +
        `\n` +
        `ID: ${tradeTicketID}` +
        `\n` +
        `*Exec Ticket*\n` +
        `Date: ${date}\n` +
        `LP: ${liquidityProvider}\n` +
        `Amt: ${tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]}\n` +
        `Price: ${execPrice + ' ' + tradingPair}\n` +
        `Platform: ${platform}\n` +
        `Order ID: ${orderId}\n`
    copy(ticket)
    setIsCopied(true)
}

  return (
    <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{
                        display: 'flex', margin: 'auto',justifyContent: 'space-around', flexDirection: {xs: 'column-reverse',sm: 'row-reverse'}, alignContent: 'center', width: '100%'
                    }}>
                    <Stack>
                    <Stack spacing={1}>
                        {isCopied?
                        <Button color='success' onClick={() => handleCopyTradeTicket()}>Copied!</Button>
                        :<Button color='primary' onClick={() => handleCopyTradeTicket()}>Copy Trade Ticket</Button>}
                    </Stack>
                        <Typography id="modal-modal-description" sx={{ mt: 2, alignSelf: 'center' }}>
                            <h2>New Ticket</h2>
                            
                            <p style={{margin: 0}}>Client: {clientName}</p>
                            <p style={{margin: 0}}>Date: {date}</p>
                            <p style={{margin: 0}}>CCY Deposited: {
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                            }</p>
                            <p style={{margin: 0}}>Spot Price: {
                                askingTermsSelect == 'Fiat'? 
                                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                }
                            </p>
                            <p style={{margin: 0}}>Dealt Rate: {
                                askingTermsSelect == 'Fiat'? 
                                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                            }</p>
                            <p style={{margin: 0}}>CCY Purchased: {
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                            }</p>
                            <p style={{marginTop: 0}}></p>
                            <p style={{marginTop: 0}}>ID: {tradeTicketID}</p>
                            <p style={{marginTop: 0}}></p>
                            <p style={{marginBottom: 1}}>*Exec Ticket*</p>
                            <span> </span>
                            <span> </span>
                            <p style={{margin: 0}}>Date: {date}</p>
                            <p style={{margin: 0}}>LP: {liquidityProvider}</p>
                            <p style={{margin: 0}}>Amt: {tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]}</p>
                            <p style={{margin: 0}}>Price: {execPrice}</p>
                            <p style={{margin: 0}}>Platform: {platform}</p>
                            <p style={{margin: 0}}>Order ID: {orderId}</p>
                        </Typography>
                    </Stack>
                    <Stack>
                      <Stack spacing={1}>
                          {isIndicativeCopied?
                          <Button color='success' onClick={() => handleCopyIndicativeTicket()}>Copied!</Button>
                          :<Button color='warning' onClick={() => handleCopyIndicativeTicket()}>Copy Indicative Ticket</Button>}
                      </Stack>
                      <Typography id="modal-modal-description" sx={{ mt: 2, alignSelf: 'center' }}>
                            <h2>New Indicative</h2>
                            
                            <p style={{margin: 0}}>Client: {clientName}</p>
                            <p style={{margin: 0}}>Date: {date}</p>
                            <p style={{margin: 0}}>CCY Deposited: {
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                            }</p>
                            <p style={{margin: 0}}>Spot Price: {
                                askingTermsSelect == 'Fiat'? 
                                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                }
                            </p>
                            <p style={{margin: 0}}>Dealt Rate: {
                                askingTermsSelect == 'Fiat'? 
                                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                            }</p>
                            <p style={{margin: 0}}>CCY Purchased: {
                                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                            }</p>
                        </Typography>
                    </Stack>
                    <Box>
                    {previousTradeData ? (
                        <Box sx={{marginTop: '5em', height: '200px'}}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Previous Trade
                          </Typography>
                          <Typography variant="body1">
                            Trade ID: {previousTradeData.trade_id}
                          </Typography>
                          <Typography variant="body1">
                            Type: {previousTradeData.type}
                          </Typography>
                          <Typography variant="body1">
                            Client: {previousTradeData.client}
                          </Typography>
                          <Typography variant="body1">
                            Date: {new Date(previousTradeData.date).toLocaleString()}
                          </Typography>
                          <Typography variant="body1">
                            Currency Deposited: {previousTradeData.ccy_deposited}
                          </Typography>
                          <Typography variant="body1">
                            Spot Price: {previousTradeData.spot_price}
                          </Typography>
                          <Typography variant="body1">
                            Dealt Rate: {previousTradeData.dealt_rate}
                          </Typography>
                          <Typography variant="body1">
                            Asset Pair: {previousTradeData.asset_pair}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body1">No trade data available.</Typography>
                      )}
                    </Box>
                </Box>  {/* This is the box that holds both divs */}
                <Stack spacing={1} sx={{width: '100%', marginTop: '10px'}}>
                        {loading? 
                        <LoadingButton loading variant="outlined">
                            Submit
                        </LoadingButton>
                        :<Button variant='contained' color='primary' onClick={() => {submitTicket(); setIsCopied(false); setIsIndicativeCopied(false)}}>Submit</Button>}
                        <Button variant='contained' color='error' onClick={() => {handleClose(); setIsCopied(false); setIsIndicativeCopied(false)}}>Cancel</Button>
                    </Stack>
                    </Box>

            </Modal>
  );
};

export default CancelTradeModal;
